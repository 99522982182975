import { useEffect } from "react";

function getProfile () {
  return {
    name: localStorage.getItem("name"),
    email: localStorage.getItem("email"),
    image: localStorage.getItem("image"),
    phone: localStorage.getItem("phone"),
    access_token: localStorage.getItem("access_token"),
  };
}

function dateFormate (val) {
  let objectDate = new Date(val);

  let day = objectDate.getDate();
  let month = objectDate.getMonth() + 1;
  let year = objectDate.getFullYear();

  return `${ year }-${ month }-${ day }`;
}

function useTitle (title) {
  useEffect(() => {
    const prevTitle = document.title;
    document.title = "POPUP PUSH - " + title;
    return () => {
      document.title = "POPUP PUSH - " + prevTitle;
    };
  });
}

//To format media size
const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

function formatSize (x) {
  let l = 0,
    n = parseInt(x, 10) || 0;
  while (n >= 1024 && ++l) {
    n = n / 1024;
  }
  return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l];
}

//To Format Total duration HH:MM:SS
function formatDuration (totalDuration) {
  var hours = Math.floor(totalDuration / 3600);
  var minutes = Math.floor((totalDuration - hours * 3600) / 60);
  var seconds = totalDuration - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }

  return hours + ":" + minutes + ":" + seconds;
}


//get forms validation errors
const getFormErrorMessage = (name, errors) => {
  return errors[name] && <small className="p-error">{ errors[name].message }</small>
};

//Get timezone 
const date = new Date();
const englishTimeZoneFormatter = new Intl.DateTimeFormat('en', {
    timeZoneName: 'long'
});
const englishTimeZone = englishTimeZoneFormatter.formatToParts(date)
    .find(part => part.type === 'timeZoneName')
    .value;


    // Used in drag and drop components
const onDragEnd = (result, columns, setColumns) => {
  if (!result.destination) return;
  const { source, destination } = result;
  if (source.droppableId !== destination.droppableId) {
    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];
    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];
    const [removed] = sourceItems.splice(source.index, 1);
    destItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: { ...sourceColumn, items: sourceItems, },
      [destination.droppableId]: { ...destColumn, items: destItems, },
    });

  } else {
    const column = columns[source.droppableId];
    const copiedItems = [...column.items];
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns, [source.droppableId]: { ...column, items: copiedItems, },
    });
  }
};

export {
  englishTimeZone, 
  
  onDragEnd,
  getProfile,
  useTitle,
  dateFormate,
  formatSize,
  formatDuration,
  getFormErrorMessage
};
