import React from 'react';
import { Dialog } from 'primereact/dialog';

import { usePackageContext } from '../../Contexts/PackagesContext';
import { useGlobalContext } from '../../Contexts/GlobalContext';
import FormWizard from './FormWizard/FormWizard';

function CreateUpdatePackage () {
    const { openDialog, dialogHandler, } = useGlobalContext();
    const { selectedPackage } = usePackageContext();

    return (
        <Dialog visible={ openDialog.createPackage }
            breakpoints={ { '960px': '95vw' } }
            style={ { width: '50%' } }
            header={ `${ Object.keys(selectedPackage).length > 0 ? "Update " : "Create " } Package` }
            modal
            className="p-fluid  "
            onHide={ () => dialogHandler("createPackage") }
        >
            <FormWizard />
        </Dialog>
    )
}

export default CreateUpdatePackage