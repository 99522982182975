import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { DataTable } from 'primereact/datatable';
import { Tooltip } from 'primereact/tooltip';
import { Column } from 'primereact/column';

import { useTitle, formatSize } from '../../config/global';
import { NetworkManager } from '../../config/NetworkManager';
import UpdateCompany from './components/UpdateCompany';
import Layout from "../Layout/Layout";

import { useGlobalContext } from '../Contexts/GlobalContext';

const CompaniesDataTable = () => {
    useTitle('Companies');

    const { openDialog, dialogHandler } = useGlobalContext();

    const [totalRecords, setTotalRecords] = useState(0);
    const [customers, setCustomers] = useState(null);
    const [loading, setLoading] = useState(false);
    const [company, setCompany] = useState({ name: "" });

    //Rerender parent passed on child components updates
    const [reload, setReload] = useState(false);

    const navigate = useNavigate();
    const Service = new NetworkManager();
    let loadLazyTimeout = null;

    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 15,
        page: 0,
        sortField: null,
        sortOrder: 1,
        filters: {
            'name': { value: '', matchMode: 'contains' },
            'email': { value: '', matchMode: 'contains' },
            'status': { value: '', matchMode: 'contains' },
        }
    });

    //Data Table Handlers
    const loadLazyData = () => {
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }
        //imitate delay of a backend call
        loadLazyTimeout = setTimeout(() => {
            Service.datatable('companies', lazyParams).then(data => {
                setTotalRecords(data.pagination.total);
                setCustomers(data.data);
                setLoading(false);
            });
        }, Math.random() * 1000 + 250);
    }

    const onPage = (event) => {
        setLazyParams(event);
    }

    const onSort = (event) => {
        setLazyParams(event);
    }

    const onFilter = (event) => {
        event['first'] = 0;
        setLazyParams(event);
    }

    useEffect(() => {
        loadLazyData();
    }, [lazyParams])

    useEffect(() => {
        if (reload) {
            loadLazyData()
        }
        setReload(false)
    }, [reload])

    // Data Table Body Template
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="d-inline-block text-nowrap">
                {/* Show company */ }
                <Tooltip target={ `.show-button-${ rowData.id }` } showDelay={ 100 } className="fs-8" />
                <button className={ `btn btn-sm btn-icon show-button-${ rowData.id }` }
                    data-pr-position="bottom"
                    data-pr-tooltip="show"
                    onClick={ () => showCompany(rowData) }>
                    <i class="fas fa-eye"></i>
                </button>

                {/* Update company */ }
                <Tooltip target={ `.update-button-${ rowData.id }` } showDelay={ 100 } className="fs-8" />
                <button className={ `btn btn-sm btn-icon update-button-${ rowData.id }` }
                    data-pr-position="bottom"
                    data-pr-tooltip="Update"
                    onClick={ () => editCompany(rowData) }>
                    <i className="pi pi-pencil"></i>
                </button>

            </div>
        );
    }

    const statusBodyTemplate = (rowData) => {
        return (
            <div className='text-center'>
                <span className={ `text-center badge ${ rowData.status == "INACTIVE" ? "bg-label-danger" : "bg-label-success" } ` }>{ rowData.status.toLowerCase() }</span>
            </div>
        );
    }

    const emailBodyTemplate = (rowData) => {
        return (
            <div className='white-space-nowrap overflow-hidden text-truncate' >{ rowData.email }</div>
        );
    }

    const allowedMediaUsage = (rowData) => {
        return formatSize(rowData.allowed_media_usage)
    }
    const usedMediaStorage = (rowData) => {
        return formatSize(rowData.total_media_usage)
    }

    // Actions Functionality
    const editCompany = (data) => {
        var str = formatSize(data.allowed_media_usage)
        data.allowed_media_usage = parseInt(str.substring(0, str.indexOf(' ')))
        setCompany(data);
        dialogHandler("updateCompany");
    }

    const showCompany = (data) => {
        navigate(`/companies/${ data.id }/show`);
    }

    return (
        <Layout>
            <div className="card">
                <div className="card-header d-flex align-items-center justify-content-between">
                    <h4 className="card-title m-0 me-2">Clients </h4>
                </div>

                <div className='table-responsive text-nowrap'>
                    <DataTable
                        value={ customers }
                        lazy
                        filterDisplay="row"
                        responsiveLayout="scroll"
                        dataKey="id"
                        paginator
                        first={ lazyParams.first }
                        rows={ lazyParams.rows }
                        rowsPerPageOptions={ [15, 25, 50, 100] }
                        totalRecords={ totalRecords }
                        onPage={ onPage }
                        onSort={ onSort }
                        onFilter={ onFilter }
                        sortField={ lazyParams.sortField }
                        sortOrder={ lazyParams.sortOrder }
                        filters={ lazyParams.filters }
                        loading={ loading }
                        className="table"
                    >
                        <Column field="name" header="Name" className='text-center' sortable filter filterPlaceholder="Search by Name" />
                        <Column field="email" className='text-start' header="Email" style={ { maxWidth: 220 } } body={ emailBodyTemplate } sortable filter filterPlaceholder="Search by Email" />
                        <Column field="allowed_device_number" className='text-center' header="Maximum devices " sortable />
                        <Column field="allowed_media_usage" className='text-center' body={ allowedMediaUsage } header="Maximum storage" sortable />
                        <Column field="total_media_usage" className='text-center' header="Used Storage" sortable body={ usedMediaStorage } />
                        <Column field="status" header="Status" className='text-center' sortable body={ statusBodyTemplate } filter filterPlaceholder="Search by Status" />
                        {/* <Column field="created_at" header="Created Date" className='text-center' sortable /> */ }
                        <Column body={ actionBodyTemplate } exportable={ false } style={ { minWidth: '8rem' } }></Column>
                    </DataTable>
                </div>

                { openDialog.updateCompany && <UpdateCompany setReload={ setReload } company={ company } /> }
            </div>
        </Layout>
    );
}

export default CompaniesDataTable;
