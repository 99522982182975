import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { Spinner } from "react-bootstrap";
import { Dialog } from "primereact/dialog";

import axiosAdminInstance from "../../../config/AxiosInstances/AxiosAdminInstance";
import { useGlobalContext } from "../../Contexts/GlobalContext";
import { onDragEnd } from "../../../config/global";
import { usePackageContext } from "../../Contexts/PackagesContext";

const droppableColumns = {
    Columns: {
        name: "All Packages",
        items: [],
    }
};

function PackagesOrderingDialog ({ packages, setRerender }) {
    droppableColumns.Columns.items = Object.values(packages);
    const [columns, setColumns] = useState(droppableColumns);

    const { openDialog, dialogHandler, disableBtn, setDisableBtn, showToast } = useGlobalContext();

    const order = () => {
        setDisableBtn(true);
        let packagesIds = Object.entries(columns.Columns.items).map(item => item[1].package_id);

        axiosAdminInstance.post(`/package/order`, { "packages_ids": packagesIds }
        ).then((result) => {
            showToast('success', 'Packages Ordering', result?.data?.data?.msg);
            setRerender(true)
            dialogHandler("orderPackages")
        }).catch((error) => {
            if (error?.response?.data?.message)
                showToast('error', 'Packages Ordering', error?.response?.data?.message);
        });
        setDisableBtn(false);
    }

    return (
        <Dialog visible={ openDialog.orderPackages }
            style={ { width: '35vw' } }
            header="Update Packages Order"
            modal className="p-fluid"
            breakpoints={ { '960px': '95vw' } }
            onHide={ () => dialogHandler("orderPackages") }>

            <DragDropContext onDragEnd={ (result) => onDragEnd(result, columns, setColumns) } className="w-100" >
                {
                    Object.entries(columns).map(([columnId, column], index) => {
                        return (
                            <div className="d-flex flex-column align-items-center" key={ columnId }>
                                <Droppable droppableId={ columnId } key={ columnId }>
                                    {
                                        (provided, snapshot) => {
                                            return (
                                                <div { ...provided.droppableProps }
                                                    ref={ provided.innerRef }
                                                    className="p-3 droppable_container "
                                                >
                                                    {
                                                        column.items.map((item, index) => {
                                                            return (
                                                                <Draggable key={ `${ item.id }-${ index }` } draggableId={ `${ item.id }-${ index }` } index={ index } >
                                                                    { (provided, snapshot) => {
                                                                        return (
                                                                            <div
                                                                                ref={ provided.innerRef }
                                                                                { ...provided.draggableProps }
                                                                                { ...provided.dragHandleProps }
                                                                                style={ {
                                                                                    backgroundColor: '#f5f5f9',
                                                                                    userSelect: "none",
                                                                                    ...provided.draggableProps.style,
                                                                                } }
                                                                                className='d-flex flex-row flex-nowrap align-items-center p-3 pe-3  px-0 px-sm-2 my-2 rounded-2 border-1'>
                                                                                <i className="p-sortable-column-icon pi pi-fw pi-sort-alt me-3"></i>
                                                                                <span>{ item.name }</span>

                                                                            </div>
                                                                        );
                                                                    } }
                                                                </Draggable>
                                                            );
                                                        })
                                                    }
                                                    { provided.placeholder }
                                                </div>
                                            );
                                        } }
                                </Droppable>
                            </div>
                        );
                    }) }
            </DragDropContext>

            <div className="col-12 text-center mt-5 mt-3 d-flex  align-items-end  justify-content-center py-4 border-top border-gray">
                <button type="reset"
                    className="btn btn-label-secondary me-3 "
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    disabled={ disableBtn ? true : false }
                    onClick={ () => dialogHandler("orderPackages") }>
                    Cancel
                </button>
                <button type="submit" className="btn btn-primary me-2" disabled={ disableBtn ? true : false } onClick={ () => order() }>
                    { disableBtn ?
                        <Spinner variant="primary" as="span" animation="border" size="sm" role="status" className='mx-3' aria-hidden="true" />
                        :
                        "Order"
                    }
                </button>
            </div>
        </Dialog>
    )
}

export default PackagesOrderingDialog