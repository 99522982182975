import React, { useRef } from 'react'
import { Link, useParams } from 'react-router-dom';

 import {useGlobalContext} from "../../../Contexts/GlobalContext"
import { Menu } from 'primereact/menu';
import axiosAdminInstance from '../../../../config/AxiosInstances/AxiosAdminInstance';
 
function SerialNav ({ deviceInfo }) {
    const {showToast } = useGlobalContext();
    
    const menu = useRef(null);
    const { id } = useParams();

    const flushHandler = () => {
        axiosAdminInstance.get(`/device/${ id }/restart`)
        .get(`/device/${ id }/screen` )
            .then((result) => {
                showToast("success", "Device", result?.data?.data?.msg)
            })
    }

    const restartHandler = () => {
        axiosAdminInstance.get(`/device/${ id }/restart`)
            .then((result) => {
                showToast("success", "Device", result?.data?.data?.msg)
            }) 
    }

    const options = [
        {
            items: [
                {
                    label: 'Live Update',
                    icon: 'bx bx-support ',
                    command: () => {
                        // setUpdateMediaDialog(true)
                    }
                },
                {
                    label: 'Flush',
                    icon: 'bx bx-help-circle',
                    command: () => {
                        flushHandler()
                    }
                },
                {
                    label: 'Restart',
                    icon: 'bx bx-power-off',
                    command: () => {
                        restartHandler()
                    }
                }
            ]
        },
    ];

    return (
        <nav className="layout-navbar navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme w-100 mt-0 mb-3" id="layout-navbar">
             <div className="navbar-nav-right d-flex align-items-center " id="navbar-collapse">
                <Link>
                    <div className="d-flex">
                        <div>
                            <h6 className="mt-3">{ deviceInfo.serial_name }&nbsp;	&nbsp;	&nbsp;	&nbsp;	{ deviceInfo.token }</h6>
                        </div>
                    </div>
                </Link>
                <div className="navbar-nav flex-row align-items-center ms-auto d-lg-flex d-none">
                    <Link className="dropdown-item" to="#">
                        <i className="bx bx-support me-2" />
                        <span className="align-middle">Live Update</span>
                    </Link>

                    <button className="dropdown-item" onClick={ flushHandler }>
                        <i className="bx bx-help-circle me-2" />
                        <span className="align-middle">Flush</span>
                    </button>

                    <button className="dropdown-item" onClick={ restartHandler }>
                        <i className="bx bx-power-off me-2" />
                        <span className="align-middle">Restart</span>
                    </button>
                </div>

                <span className='text-end ms-auto cursor-pointer d-flex d-lg-none'
                    onClick={ (event) => menu.current.toggle(event) } >
                    <i className="fas fa-ellipsis-v text-light fs-4" tooltip="option"
                        tooltipOptions={ { position: 'bottom' } } onClick={ (event) => menu.current.toggle(event) } aria-controls="popup_menu" aria-haspopup></i>
                    <Menu model={ options } popup ref={ menu } id="popup_menu" />
                </span>

            </div>
        </nav>
    )
}

export default SerialNav