import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';

import { useCompaniesContext } from '../../Contexts/CompaniesContext';
import { useGlobalContext } from '../../Contexts/GlobalContext';
import axiosAdminInstance from '../../../config/AxiosInstances/AxiosAdminInstance';
import { useTitle } from '../../../config/global';

import DataTablesMenu from './CompanyProfile/DataTablesMenu';
import CompanyInfo from './CompanyProfile/CompanyInfo'
import Layout from '../../Layout/Layout'

function CompanyProfile () {
  useTitle("Company Profile")

  const { setCompanyInfo, companyInfo, setPackageOptions } = useCompaniesContext();
  const { subLoaders, loadingHandler } = useGlobalContext();
  const { id } = useParams();

  useEffect(() => {
    loadingHandler("showCompany")
    axiosAdminInstance.get(`/companies/${ id }/show`)
      .then(result => {
        setCompanyInfo(result?.data?.data)
        setPackageOptions(result?.data?.data?.packages)
        loadingHandler("showCompany")
      })

  }, [])

  return (
    <Layout>
      <CompanyInfo />
      <DataTablesMenu />
    </Layout>
  )
}

export default CompanyProfile