import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

import logo from "../../assets/img/logo.png";
import "./css/Menu.css";

function MenuList () {
    const location = useLocation();

    const [isMenuOpen, setIsMenuOpen] = useState(location.pathname.includes("/campaign") ? true : false);
    const [pathName, setPathName] = useState("");

    useEffect(() => {
        const pathname = window.location.pathname;
        const firstSlashIndex = pathname.indexOf('/');
        const secondSlashIndex = pathname.indexOf('/', firstSlashIndex + 1);
        const contentBetweenSlashes = pathname.substring(firstSlashIndex + 1, secondSlashIndex);

        setPathName(contentBetweenSlashes);
    }, [])

    const menuItem = [
        {
            path: "/dashboard",
            name: "Dashboard",
            icon: <i className="menu-icon bx bx-home-circle mb-1" />,
        },
        {
            path: "/packages/list",
            name: "Packages",
            icon: <i class='menu-icon bx bxs-badge-dollar'></i>,

        },
        {
            path: "/clients",
            name: "Clients",
            icon: <i className="menu-icon tf-icons bx  bxs-business"></i>,
        },
        {
            path: "/devices/list",
            name: "Devices",
            icon: <i className="menu-icon tf-icons bx bx-desktop"></i>,
        }
        // {
        //     path: "/reports",
        //     name: "Reports",
        //     icon: <i className="menu-icon tf-icons bx bx-carousel" />,
        // },

    ];

    const NavbarLink = ({ item, index, subItem = false }) => {
        return (
            <NavLink to={ item.path } key={ index }
                className={ `menu-item ${ pathName == item.name.toLocaleLowerCase() && ("submenu" in item) ? "active" : "" }` }
            >
                <li className="d-flex flex-row  p-2 menu-link">
                    <div>{ item.icon }</div>
                    <div>{ item.name }</div>
                </li>
            </NavLink>
        )
    }

    const SubMenu = ({ item, index }) => {
        return (
            <>
                <div onClick={ () => setIsMenuOpen(!isMenuOpen) } key={ index }
                    className={ `menu-item ${ pathName == item.name.toLocaleLowerCase() && ("submenu" in item) ? "active" : "" }` }>
                    <li className="d-flex flex-column align-items-start p-2 menu-link cursor-pointer" >
                        <div className="d-flex flex-row  w-100">
                            <div >{ item.icon }</div>
                            <div className="d-flex justify-content-between align-items-center w-100"  >
                                <span>{ item.name }</span>
                                {
                                    isMenuOpen ?
                                        <i className="fas fa-chevron-down fs-10"></i>
                                        :
                                        <i className="fas fa-angle-right fs-10"></i>
                                }
                            </div>
                        </div>
                    </li>
                </div>
                {
                    isMenuOpen &&
                    <ul className="ps-3 d-block w-100 flex-grow-0 h-auto  menu-inner">
                        {
                            item.submenu.map((item, index) => {
                                return <NavbarLink item={ item } index={ index } subItem={ true } />
                            })
                        }
                    </ul>
                }
            </>
        )
    }

    return (
        <>
            <div className="app-brand demo">
                <a href={ process.env.REACT_APP_FRONTEND_URL } target="_blank" className="app-brand-link gap-2 mt-3 mb-3" style={ { margin: "auto" } }>
                    <img src={ logo } className="img-fluid" width={ 200 } alt="" />
                </a>
            </div>

            <div className="menu-inner-shadow"></div>

            {/* =============== Menu Sorting Code =============== */ }
            <ul className="menu-inner">
                { menuItem.map((Item, index) => {

                    return "submenu" in Item ?
                        <SubMenu item={ Item } index={ index } />
                        :
                        <NavbarLink item={ Item } index={ index } />

                }) }
            </ul>
        </>
    )
}

export default MenuList