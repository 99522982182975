import React from 'react';
import { Controller } from "react-hook-form";

import { getFormErrorMessage, } from '../../../../config/global';

import { classNames } from 'primereact/utils';
import { Checkbox } from 'primereact/checkbox';
import { usePackageContext } from '../../../Contexts/PackagesContext';

const MediaTypes = ["image", "video", "audio", "livestream", "wep", "satellite", "hdmi", "usb", "youtube"];
const Layers     = ["main", "corner", "playground", "banner"];

function Step3 ({ control, errors, clearErrors, setError }) {
  const { selectedMediaTypes, setSelectedMediaTypes, selectedLayers, setSelectedLayers } = usePackageContext();

  const onMediaTypesChange = (e) => {
    let _selectedMediaTypes = [...selectedMediaTypes];

    if (e.checked)
      _selectedMediaTypes.push(e.value);
    else
      _selectedMediaTypes.splice(_selectedMediaTypes.indexOf(e.value), 1);

    if (_selectedMediaTypes.length > 0) {
      clearErrors("media_types")
    } else {
      setError('media_types', { type: 'manual', message: 'Please, select allowed media type! ' });
    }

    setSelectedMediaTypes(_selectedMediaTypes);
  }

  const onLayerChange = (e) => {
    let _selectedLayer = [...selectedLayers];

    if (e.checked)
      _selectedLayer.push(e.value);
    else
      _selectedLayer.splice(_selectedLayer.indexOf(e.value), 1);


    if (_selectedLayer.length > 0) {
      clearErrors("layers")
    } else {
      setError('layers', { type: 'manual', message: 'One Layer at least is required!' });
    }

    setSelectedLayers(_selectedLayer);
  }

  return (
    <>
      <div className=' fw-bolder fs-5 mb-3'>
        Media Types
      </div>

      <div className="col-12  d-flex mb-3 flex-wrap">
        {
          MediaTypes.map((type_name, index) => {
            return (
              <div key={ index } className='col-6 d-flex mb-3'>
                <div className="field me-2 d-flex align-items-center ">
                  <span className="p-float-label me-2">
                    <Controller name={ `${ type_name }` } control={ control }
                      rules={ { required: false } }
                      render={ ({ field, fieldState }) => (
                        <Checkbox id={ field.name }
                          name="features"
                          { ...field }
                          value={ `${ type_name }` }
                          inputRef={ field.ref }
                          checked={ selectedMediaTypes.indexOf(`${ type_name }`) !== -1 }
                          onChange={ onMediaTypesChange }
                          className={ `${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                          inputId={ type_name + "-" + index }
                        />
                      ) } />
                  </span>
                  <label className="text-capitalize fs-6 cursor-pointer" htmlFor={ type_name + "-" + index }>{ type_name.replace(/_/g, " ") }</label>
                </div>
              </div>
            )
          })
        }
        <div className="col-12  d-flex  flex-wrap">

          { getFormErrorMessage("media_types", errors) }
        </div>
      </div>


      <div className=' fw-bolder fs-5 mb-3'>
        Multi Layer
      </div>

      <div className="col-12  d-flex flex-wrap">
        {
          Layers.map((type_name, index) => {
            return (
              <div key={ index } className='col-6 d-flex mb-3'>
                <div className="field me-2 d-flex align-items-center ">
                  <span className="p-float-label me-2">
                    <Controller name={ `${ type_name }` } control={ control }
                      rules={ { required: false } }
                      render={ ({ field, fieldState }) => (
                        <Checkbox id={ field.name }
                          name="features"
                          { ...field }
                          value={ `${ type_name }` }
                          inputRef={ field.ref }
                          checked={ selectedLayers.indexOf(`${ type_name }`) !== -1 }
                          onChange={ onLayerChange }
                          className={ `${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                          inputId={ type_name + "-" + index }
                        />
                      ) } />
                  </span>
                  <label className="text-capitalize fs-6  cursor-pointer" htmlFor={ type_name + "-" + index }>{ type_name.replace(/_/g, " ") }</label>
                  { getFormErrorMessage(type_name, errors) }
                </div>
              </div>
            )
          })
        }
      </div>
      { getFormErrorMessage("layers", errors) }

    </>
  )
}

export default Step3