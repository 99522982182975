import axiosAdminInstance from "./AxiosInstances/AxiosAdminInstance";

const joinedDataTable = ["campaign", "subscriptions", "logs"]

export class NetworkManager {
    datatable (table, params) {
        let ordered_col = params.sortField ? params.sortField : 'created_at';
        let ordered_type = params.sortOrder == 1 ? "desc" : 'asc';

        const pushed = {
            "order_by": {
                [ordered_col]: ordered_type
            },
            "filters": params.filters,
            "filters_date": params.filters_date,
            "company_id": params.company_id ?? ""
        };

        if (joinedDataTable.indexOf(table) !== -1) {
            return axiosAdminInstance.post(`/datatable/${ table }?page=${ params.page + 1 }&per_page=${ params.rows }`, pushed)
                .then(res => res.data)
        } else
            return axiosAdminInstance.post(`/datatable/${ table }/view?page=${ params.page + 1 }&per_page=${ params.rows }`, pushed)
                .then(res => res.data)
    }

    deleteRow (table, params) {
        axiosAdminInstance.delete(`/datatable/${ table }/${ params.id }/delete`);
    }
}
