import React from 'react';

const Map = () => {
    return (<></>
        // <div id="map-container-google-1" className="z-depth-1-half map-container  " style={{ height: 300 }}>
        //     <iframe src="https://maps.google.com/maps?q=manhatan&t=&z=13&ie=UTF8&iwloc=&output=embed"
        //         frameBorder={ 0 } className="w-100 h-100" allowFullScreen />
        // </div>
    );
}

export default Map;