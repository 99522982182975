import React, { useState, useEffect } from 'react';
import { Controller } from "react-hook-form";

import { getFormErrorMessage } from '../../../../config/global';
import { usePackageContext } from '../../../Contexts/PackagesContext';

import { InputNumber } from 'primereact/inputnumber';
import { classNames } from 'primereact/utils';

function Step4 ({ control, errors }) {
    const { selectedPeriods, setSelectedPeriods, periodPrices, setPeriodPrices } = usePackageContext();

    const [price, setPrice] = useState(periodPrices["one_month"]);

    // Price inputs handler
    const onPercentageChange = (percentage, periodName) => {
        const updatedItems = selectedPeriods.map(item => {
            let _discountPrice = 0

            if (item.name === periodName) {
                _discountPrice =  (price * item.period) - (price * item.period * (percentage / 100)).toFixed(2)
                setPeriodPrices(prev => ({ ...prev, [periodName]: _discountPrice }))

                return {
                    ...item,
                    percentage: percentage,
                    price: _discountPrice
                }

            } else {
                return item
            }
        });

        setSelectedPeriods(updatedItems);
    }

    useEffect(() => {
        let updatedItems = selectedPeriods.map(item => {
            let _discountPrice =  ((price * item.period) - (price * item.period * (item.percentage / 100))).toFixed(2)
            setPeriodPrices(prev => ({ ...prev, [item.name]: _discountPrice }))

            return {
                ...item,
                percentage: item.percentage,
                price: _discountPrice
            }
        });

        setSelectedPeriods(updatedItems);
    }, [price])


    return (
        <>
            <div className="d-flex flex-column mx-5 px-3 pricing">
                <div className="field me-2 w-100 mb-4 ">
                    <label className="text-capitalize fs-6 ">Basic Price</label>
                    <span className="p-float-label me-2">
                        <Controller name="price" control={ control }
                            rules={ { required: "This field is required!" } }
                            render={ ({ field, fieldState }) => (
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">$</span>
                                    <InputNumber
                                        id={ field.name }
                                        { ...field }
                                        value={ field.value }
                                        inputRef={ field.ref }
                                        onChange={ (e) => {
                                            field.onChange(e.value);
                                            setPrice(e.value);
                                            onPercentageChange(0, "one_month")
                                        } }
                                        className={ `w-100  ${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                                        mode="decimal" useGrouping={ false } />
                                    {/* <span className="p-inputgroup-addon">$</span> */ }
                                </div>
                            ) } />
                    </span>
                    { getFormErrorMessage("price", errors) }
                </div>

                <div className="field me-2 w-100 mb-4 ">
                    <label className="text-capitalize fs-6 "> six months discount percentage </label>
                    <span className="p-float-label me-2">
                        <Controller name="six_months_price" control={ control }
                            rules={ { required: "This field is required!" } }
                            render={ ({ field, fieldState }) => (
                                <div className="p-inputgroup">
                                    <span className=" col-1 p-inputgroup-addon">%</span>

                                    <InputNumber
                                        id={ field.name }
                                        { ...field }
                                        value={ field.value }
                                        inputRef={ field.ref }
                                        onChange={ (e) => { field.onChange(e.value); onPercentageChange(e.value, "six_months") } }
                                        className={ `w-100 col-8  ${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                                        useGrouping={ false } />

                                    <span className="col-3 p-inputgroup-addon pricing fw-bold">{ periodPrices["six_months"] } $</span>
                                </div>
                            ) } />
                    </span>
                    { getFormErrorMessage("six_months_price", errors) }
                </div>

                <div className="field me-2 w-100 ">
                    <label className="text-capitalize fs-6 ">one year discount percentage</label>
                    <span className="p-float-label me-2">
                        <Controller name="one_year_price" control={ control }
                            rules={ { required: "This field is required!" } }
                            render={ ({ field, fieldState }) => (
                                <div className="p-inputgroup ">
                                    <span className="p-inputgroup-addon col-1 ">%</span>
                                    <InputNumber
                                        id={ field.name }
                                        { ...field }
                                        value={ field.value }
                                        inputRef={ field.ref }
                                        onChange={ (e) => { field.onChange(e.value); onPercentageChange(e.value, "one_year") } }
                                        className={ `w-100 col-8 ${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                                        useGrouping={ false } />
                                    <span className="p-inputgroup-addon col-3  pricing fw-bold">{ periodPrices["one_year"] } $</span>
                                </div>
                            ) } />
                    </span>

                    { getFormErrorMessage("one_year_price", errors) }
                </div>

            </div>
        </>
    )
}

export default Step4