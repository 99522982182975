import React from 'react';
import { useNavigate, Link } from 'react-router-dom'
import axiosApiInstance from '../../../config/AxiosInstances/AxiosApiInstance';

const Logout = ({ classes = "" }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        axiosApiInstance.get(`/users/logout`,).then(result => {
            navigate("/");
            localStorage.removeItem("name");
            localStorage.removeItem("email");
            localStorage.removeItem("image");
            localStorage.removeItem("phone");
            localStorage.removeItem("access_token");
            localStorage.removeItem("email_verified_at");
        })


    }

    return (

        <Link className={ `dropdown-item ${ classes != "" ? classes : "" }` } to="/" onClick={ handleClick }>
            <i className="bx bx-power-off me-2" />
            <span className="align-middle">Log Out</span>
        </Link>
    );
}

export default Logout;