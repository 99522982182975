import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';

import FirebaseDashboardListener from '../../../../Services/FirebaseDashboardListener';
import { useGlobalContext } from '../../../../Contexts/GlobalContext';
import axiosAdminInstance from '../../../../../config/AxiosInstances/AxiosAdminInstance';
import { NetworkManager } from '../../../../../config/NetworkManager';
import { Spinner } from 'react-bootstrap';

function Devices () {
  const { subLoaders, loadingHandler, showToast } = useGlobalContext();
  const { id } = useParams();

  const [selectedItem, setSelectedItem] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [devices, setDevices] = useState(null);
  const [counter, setCounter] = useState(30);

  const Service = new NetworkManager();
  let loadLazyTimeout = null;

  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: null,
    sortOrder: 1,
    filters: {
      'name': { value: '', matchMode: 'contains' },
      'serial_name': { value: '', matchMode: 'contains' },
      'status': { value: '', matchMode: 'contains' },
    },
    isInteractive: 1,
    company_id: id
  });

  //Data Table Handlers
  const loadLazyData = (showLoading = true) => {
    setCounter(30);

    if (!subLoaders.devices && showLoading) {
      loadingHandler("devices");
    }

    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }

    //imitate delay of a backend call
    loadLazyTimeout = setTimeout(() => {
      Service.datatable('device', lazyParams)
        .then(data => {
          setTotalRecords(data.pagination.total);
          setDevices(data.data);
          if (showLoading)
            loadingHandler("devices");
        });
    }, Math.random() * 1000 + 250);
  }

  const onPage = (event) => {
    event['filters'] = {};
    event['company_id'] = id;
    setLazyParams(event);
  }

  const onFilter = (event) => {
    event['first'] = 0;
    event['company_id'] = id
    setLazyParams(event);
  }

  const onSort = (event) => {
    event['company_id'] = id
    setLazyParams(event);
  }

  useEffect(() => {
    loadLazyData();
  }, [lazyParams])


  const flushHandler = (data) => {
    axiosAdminInstance.get(`/devices/${ data.id }/screen`)
      .then((res) => {
        showToast('success', 'Device', res?.data?.data?.msg);
      }).catch(error => {
        showToast('error', 'Device', error?.response?.data?.message);
      });
  }

  const activationHandler = (data, status) => {
    setSelectedItem(data.id)
    loadingHandler("activation");
    axiosAdminInstance.put(
      `/devices/${ data.id }/update`,
      {
        "status": status
      }
    ).then((res) => {
      loadingHandler("activation");
      loadLazyData();
      showToast('success', 'Device', res?.data?.data?.msg);
    })
  }

  const restartHandler = (data) => {
    axiosAdminInstance.get(`/devices/${ data.id }/restart`)
      .then((res) => {
        showToast('success', 'Device', res?.data?.data?.msg);
      })
  }

  const deleteHandler = (data) => {
    loadingHandler("devices");
    axiosAdminInstance.delete(`/devices/${ data.id }`)
      .then((res) => {
        loadingHandler("devices");
        loadLazyData();
        showToast('success', 'Device', res?.data?.data?.msg);
      })
  }

  // Table body columns templates 
  const statusBodyTemplate = (rowData) => {
    return (
      <span className={ `text-center badge ${ rowData.status == "ACTIVE" ? "bg-label-success" : (rowData.status == "EXPIRED" ? "bg-label-danger" : "bg-label-warning") } ` }>
        { rowData.status.toLowerCase() }
      </span>
    );
  }

  const actionsBodyTemplate = (rowData) => {
    return (
      <div className="d-inline-block text-nowrap">

        {/* Activation company */ }
        <Tooltip target={ `.activation-button-${ rowData.id }` } showDelay={ 100 } className="fs-8" />
        <button className={ `btn btn-sm btn-icon activation-button-${ rowData.id }` }
          data-pr-position="bottom"
          data-pr-tooltip={ rowData.status == "ACTIVE" ? "Deactivation" : "Activation" }
        >
          {
            (rowData.id == selectedItem && subLoaders.activation) ?
              <Spinner animation="border" variant="primary" size="sm" aria-hidden="true" />
              :

              rowData.status == "ACTIVE" ?
                <i class="fa-solid fa-plug-circle-xmark fs-6" onClick={ () => activationHandler(rowData, "INACTIVE") } ></i>
                :
                <i class="fa-solid fa-plug-circle-check fs-6" onClick={ () => activationHandler(rowData, "ACTIVE") }></i>
          }
        </button>

        {/* Flush company */ }
        <Tooltip target={ `.flush-button-${ rowData.id }` } showDelay={ 100 } className="fs-8" />
        <button className={ `btn btn-sm btn-icon flush-button-${ rowData.id }` }
          data-pr-position="bottom"
          data-pr-tooltip="Flush"
          onClick={ () => flushHandler(rowData) }
        >
          <i className="bx bx-help-circle"></i>
        </button>

        {/* Restart company */ }
        <Tooltip target={ `.restart-button-${ rowData.id }` } showDelay={ 100 } className="fs-8" />
        <button className={ `btn btn-sm btn-icon restart-button-${ rowData.id }` }
          data-pr-position="bottom"
          data-pr-tooltip="Restart"
          onClick={ () => restartHandler(rowData) }
        >
          <i className="fas fa-power-off fs-6"></i>
        </button>

        {/* Delete company */ }
        <Tooltip target={ `.delete-button-${ rowData.id }` } showDelay={ 100 } className="fs-8" />
        <button className={ `btn btn-sm btn-icon delete-record delete-button-${ rowData.id }` }
          data-pr-position="bottom"
          data-pr-tooltip="Delete"
          onClick={ () => deleteHandler(rowData) } >
          <i className="bx bx-trash"></i>
        </button>

      </div>
    );
  }

  const onlineBodyTemplate = (rowData) => {
    return (
      <span className={ `text-center badge ${ !rowData.online ? "bg-label-danger" : "bg-label-success" } ` }>
        { rowData.online ? "On-line" : "Off-line" }
      </span>
    );
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCounter((prevCounter) => {
        const newCounter = prevCounter - 1;
        if (newCounter === 0) {
          clearInterval(intervalId);
          loadLazyData(false);
        }

        return newCounter;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);


  return (
    <div className="card">
      <div className="card-header d-flex align-items-center justify-content-between">
        <h6 className="card-title m-0 me-2">Devices List </h6>
      </div>

      <div className='table-responsive text-nowrap stream_datatable position-relative'>

        <DataTable
          value={ devices }
          lazy
          filterDisplay="row"
          responsiveLayout="scroll"
          dataKey="id"
          paginator
          first={ lazyParams.first }
          rows={ lazyParams.rows }
          rowsPerPageOptions={ [15, 25, 50, 100] }
          totalRecords={ totalRecords }
          onPage={ onPage }
          onSort={ onSort }
          onFilter={ onFilter }
          sortField={ lazyParams.sortField }
          sortOrder={ lazyParams.sortOrder }
          filters={ lazyParams.filters }
          loading={ subLoaders.devices }
          className="table text-light"
        >
          <Column field="name" header="Name" className='text-center fs-8' sortable filter />
          <Column field="serial_name" header="Serial  Name" className='text-center fs-8' sortable filter />
          <Column field="status" header="status" className=' text-center fs-8' body={ statusBodyTemplate } sortable filter />
          <Column field="online" header="Is online" className=' text-center fs-8' body={ onlineBodyTemplate } sortable filter />
          <Column field="created_at" header="created_at" className=' text-center fs-8' sortable />
          <Column field="" header=" " className='text-center fs-8' body={ actionsBodyTemplate } />
        </DataTable>

        <FirebaseDashboardListener path={ "/Dashboard/" + id + "/action" } getData={ loadLazyData } />
      </div>
    </div>
  )
}

export default Devices