import React, { useState, useEffect } from "react";
import axiosAdminInstance from "../../config/AxiosInstances/AxiosAdminInstance";
 
 import {  useTitle } from "../../config/global";
import Layout from "../Layout/Layout";

const Dashboard = () => {
  useTitle("Dashboard Page");
  const [data, setData] = useState({});

  useEffect(() => {
    axiosAdminInstance.get(`/dashboard`)
      .then((response) => {
        setData(response?.data?.data);
      })
      
  }, []);

  const [chartData] = useState({
    datasets: [
      {
        data: [10, 8],
        backgroundColor: ["#0275d8", "#292b2c"],
        label: "Devices",
      },
    ],
    labels: ["Online", "Offline"],
  });

  const [lightOptions] = useState({
    plugins: {
      legend: {
        labels: {
          color: "#495057",
        },
      },
    },
    scales: {
      r: {
        grid: {
          color: "#ebedef",
        },
      },
    },
  });

  return (
    <Layout>
      <div className="content-wrapper">
        <div className="px-0 flex-grow-1 ">
          <div className="row">
            <div className="col-lg-6 mb-1 order-0 mb-4">
              <div className="card">
                <div className="d-flex align-items-end row">
                  <div className="col-sm-6 mb-4">
                    <div className="card-body mt-2">
                      <h5 className="card-title text-primary text-capitalize">
                        Welcome { localStorage.getItem("name") }
                      </h5>
                      <p className="mb-4">
                        Start managing your screens and content from this dashboard.
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-6 text-center text-sm-left">
                    <div className="card-body pb-0 px-0 px-md-1 ms-5">
                      <img
                        src={ require("../../assets/img/illustrations/man-with-laptop-light.png") }
                        className="dashboard_img"
                        height={ 140 }
                        alt="View Badge User"
                        data-app-light-img="illustrations/man-with-laptop-light.png"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-4 order-1">
              <div className="row">
                <div className="col-6 mb-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="card-title d-flex align-items-start justify-content-between avatar flex-shrink-0 me-3">
                        <span className="avatar-initial rounded bg-label-primary">
                          <i className="bx bx-mobile-alt"></i>
                        </span>
                      </div>
                      <h5 className="fw-semibold mb-3 mt-4">Total Devices</h5>
                      <h4 className="card-title d-flex justify-content-end mb-3">
                        { data?.devices }
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="col-6 mb-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="card-title d-flex align-items-start justify-content-between avatar flex-shrink-0 me-3">
                        <span className="avatar-initial rounded bg-label-warning">
                          <i class="fas fa-users"></i>
                        </span>
                      </div>
                      <h5 className="fw-semibold mb-3 mt-4">Total Users</h5>
                      <h4 className="card-title d-flex justify-content-end mb-3">
                        { data?.users }
                      </h4>
                    </div>
                  </div>
                </div>
                {/* <div className="col-4 mb-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="card-title d-flex align-items-start justify-content-between avatar flex-shrink-0 me-3">
                        <span className="avatar-initial rounded bg-label-dark">
                          <i className="bx bx-mobile-alt"></i>
                        </span>
                      </div>
                      <h5 className="fw-semibold mb-3 mt-4">Offline Screen</h5>
                      <h4 className="card-title d-flex justify-content-end mb-3">
                        { data?.totalOfflineDevice }
                      </h4>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>

          </div>
        </div>
      </div>
    </Layout>
  );
};
export default Dashboard;
