import React from 'react'

import { Dialog } from 'primereact/dialog'

import { useCompaniesContext } from '../../../../Contexts/CompaniesContext';
import { useGlobalContext } from '../../../../Contexts/GlobalContext'

function LogPreview () {
    const { selectedLog, setSelectedLog } = useCompaniesContext();
    const { openDialog, dialogHandler } = useGlobalContext();

    return (
        <Dialog visible={ openDialog.logPreview }
            style={ { width: '500px' } }
            header="Log Data Preview"
            modal className="p-fluid"
            breakpoints={ { '960px': '95vw' } }
            onHide={ () => dialogHandler("logPreview") }>

            <ul className='px-5 py-4'>
                {
                     Array.isArray(selectedLog?.data) ?
                        Object.entries(selectedLog.data[0]).map(([key, value]) => {
                            return <li className='mb-2'><span className='fw-bold'>{ key } : </span> { value ?? "--" }</li>
                        })
                        :
                        Object.entries(selectedLog.data).map(log => {
                             return <li>{ log }</li>
                        })
                }
            </ul>

        </Dialog>
    )
}

export default LogPreview