import React from 'react'

function LiveScreen() {
    return (
        <div className="screen">
            <div className="static d-flex align-items-center justify-content-center h-px-500">
                <h1>There is no live streaming...</h1>
            </div>
            <div className="scan"></div>
        </div>
    )
}

export default LiveScreen;