import React, { useEffect } from 'react';
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from 'react-router-dom';

import { classNames } from 'primereact/utils';
import { Dropdown } from 'primereact/dropdown';
import { Spinner } from 'react-bootstrap';
import { Dialog } from 'primereact/dialog';

import { getFormErrorMessage } from '../../../config/global';
 import { useGlobalContext } from '../../Contexts/GlobalContext';
import axiosAdminInstance from '../../../config/AxiosInstances/AxiosAdminInstance';

const status = [
    { name: 'Active', code: 'ACTIVE' },
    { name: 'Inactive', code: 'INACTIVE' },
];

function Update ({ device, setRerender }) {

    const { formState: { errors }, control, handleSubmit, reset } = useForm();
    const { disableBtn, setDisableBtn, showToast, openDialog, dialogHandler } = useGlobalContext();

    useEffect(() => {
        reset(device)
    }, [device])

    const onSubmit = (data) => {
        setDisableBtn(true)

        axiosAdminInstance.put(`/devices/${ device.id }/update`, data)
            .then((result) => {
                setDisableBtn(false)
                dialogHandler("updateDevice");
                setRerender(true)
                showToast('success', ' Device Updating', result?.data?.data?.msg)
            }).catch((error) => {
                setDisableBtn(false);
                if (error?.response?.data?.errors)
                    Object.values(error?.response?.data?.errors).forEach(error => {
                        showToast('error', ' Device Updating', error[0])
                    });
            });

    };

    return (
        <>
            <Dialog visible={ openDialog.updateDevice }
                style={ { width: '500px' } }
                header="Update Device Status"
                modal className="p-fluid"
                breakpoints={ { '960px': '95vw' } }
                onHide={ () => dialogHandler("updateDevice")
                }>

                <form onSubmit={ handleSubmit(onSubmit) }>
                    {/* Status input */ }
                    <div className={ `col-12 my-5 px-4` }>
                        <div className="field">
                            <label className="form-label" htmlFor="basic-default-company">  Status </label>
                            <span className="p-float-label">
                                <Controller name="status" control={ control }
                                    rules={ { required: 'Status is required.' } }
                                    render={ ({ field, fieldState }) =>
                                    (<Dropdown id={ field.name } { ...field }
                                        onChange={ (e) => field.onChange(e.value) }
                                        options={ status }
                                        optionLabel="name"
                                        inputRef={ field.ref }
                                        optionValue="code"
                                        className={ `w-100  ${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                                    />)
                                    } />
                                { getFormErrorMessage('status', errors) }
                            </span>
                        </div>
                    </div>


                    <div className="col-12 text-center d-flex  align-items-end  justify-content-center py-4 border-top border-gray">
                        <button href='/campaigns/list' type="submit" className="btn btn-primary me-2" disabled={ disableBtn ? true : false }>
                            { disableBtn ?
                                <Spinner as="span" animation="border" size="sm" role="status" className='mx-3' aria-hidden="true" />
                                :
                                "Update"
                            }
                        </button>
                        <button type="reset" className="btn btn-label-secondary me-3 " data-bs-dismiss="modal" aria-label="Close"
                            disabled={ disableBtn ? true : false }
                            onClick={ () => dialogHandler("updateDevice") }
                        >
                            Cancel
                        </button>
                    </div>
                </form>
            </Dialog>
        </>
    )
}

export default Update