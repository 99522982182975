import React, { useState, useEffect } from 'react'

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useGlobalContext } from '../../../../Contexts/GlobalContext';
import { NetworkManager } from '../../../../../config/NetworkManager';
import { useParams } from 'react-router-dom';
import AddSubscription from './AddSubscription';

function Subscriptions () {
  const { subLoaders, loadingHandler, openDialog, dialogHandler, } = useGlobalContext();
  const { id } = useParams();

  const [totalRecords, setTotalRecords] = useState(0);
  const [subscriptions, setSubscriptions] = useState(null);

  const Service = new NetworkManager();
  let loadLazyTimeout = null;

  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: null,
    sortOrder: 0,
    filters: {},
    isInteractive: 1,
    company_id: id
  });

  //Data Table Handlers
  const loadLazyData = () => {
    if (!subLoaders.subscriptions)
      loadingHandler("subscriptions");

    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }

    //imitate delay of a backend call
    loadLazyTimeout = setTimeout(() => {
      Service.datatable('subscriptions', lazyParams)
        .then(data => {
          if (subLoaders.reload)
            loadingHandler("reload");
          setTotalRecords(data.pagination.total);
          setSubscriptions(data.data);
          loadingHandler("subscriptions");
        });
    }, Math.random() * 1000 + 250);
  }

  const onPage = (event) => {
    event['filters'] = {};
    event['company_id'] = id;
    setLazyParams(event);
  }

  useEffect(() => {
    loadLazyData();
  }, [lazyParams])

  useEffect(() => {
    if (subLoaders.reload)
      loadLazyData();
  }, [subLoaders.reload])

  const periodBodyTemplate = (rowData) => {
    return (
      <div className=''>
        <span className="text-center text-capitalize" >
          { rowData.is_free ? "7 days" : rowData.period.toLowerCase().replace(/_/g, " ") }
        </span>

      </div>
    );
  }

  // Table body columns templates 
  const statusBodyTemplate = (rowData) => {
    return (
      <span className={ `text-center badge ${ rowData.status == "INACTIVE" ? "bg-label-danger" : "bg-label-success" } ` }>
        { rowData.status.toLowerCase() }
      </span>
    );
  }

  const amountBodyTemplate = (rowData) => {
    return rowData.paid_price == 0 ? "$ Free" : "$ " + rowData.paid_price
  }

  const typeBodyTemplate = (rowData) => {
    return rowData.type.toLowerCase()

  }

  return (
    <div className="card">
      <div className="card-header d-flex align-items-center justify-content-between">
        <h6 className="card-title m-0 me-2">Transactions List </h6>
        <button type="button" className="btn btn-primary btn-sm me-sm-3 me-1"
          onClick={ () => dialogHandler("addSubscription") } disabled={ subLoaders.subscriptions ? true : false }
        >
          <i className="fa-solid fa-plus me-1"></i>   Add Subscription
        </button>
      </div>

      <div className='table-responsive text-nowrap stream_datatable position-relative'>

        <DataTable value={ subscriptions } lazy responsiveLayout="scroll" dataKey="id"
          paginator first={ lazyParams.first } rows={ lazyParams.rows }
          totalRecords={ totalRecords } onPage={ onPage }
          loading={ subLoaders.subscriptions } className="table text-light"
        >
          <Column field="subscription_id" header="Transaction ID" className='text-center text-capitalize fs-6' />
          <Column field="title" header="Package Name" className='text-center text-capitalize fs-6' />
          <Column field="paid_price" header="Amount" className='text-center text-capitalize fs-6' body={ amountBodyTemplate } />
          <Column field="period" header="period" className='text-center text-capitalize fs-6' body={ periodBodyTemplate } />
          <Column field="note" header="Payment Method" className='text-center text-capitalize fs-6' />
          <Column field="type" header="Type" className='text-center text-capitalize  fs-6' body={ typeBodyTemplate } />
          <Column field="status" header="status" className='text-center text-capitalize fs-6' body={ statusBodyTemplate } />
          <Column field="start_date" header="Start Date" className='text-center text-capitalize fs-6' />
          <Column field="end_date" header="End Date" className='text-center text-capitalize fs-6' />
          {/* <Column field="" header="Receipt" className='text-center fs-8' body={ receiptBodyTemplate } /> */ }
        </DataTable>

        {
          openDialog.addSubscription && <AddSubscription />
        }
      </div>
    </div>
  )
}

export default Subscriptions