import React, { useState, useEffect, } from "react";
import { useNavigate, useParams } from "react-router-dom";

import axiosAdminInstance from "../../../config/AxiosInstances/AxiosAdminInstance";
 import ChartComponents from "./Setup/ChartComponents";
import { useTitle } from "../../../config/global";
import LiveScreen from "./Setup/LiveScreen";
import DeviceInfo from "./Setup/DeviceInfo";
import SerialNav from "./Setup/SerialNav";
import Layout from "../../Layout/Layout";
import Map from "./Setup/Map";

const DeviceShow = () => {
  useTitle("Devices");

  const { id } = useParams();
  const navigate = useNavigate();

  const [deviceInfo, setDeviceInfo] = useState({});

  //assign campaign component states
  const [selectedCampaign, setSelectedCampaign] = useState({});
  const [options, setoptions] = useState([]);

  useEffect(() => {
    axiosAdminInstance.get(`/device/${ id }/info`).then((res) => {
      setDeviceInfo(res.data.data);
      setSelectedCampaign(res.data.data.campaigns[0].id)
    })

    axiosAdminInstance.get(`/company/campaigns?page=1&per_page=12`)
      .then((result) => {
        result.data.data.map(campaign => {
          let option = {
            name: campaign.name,
            code: campaign.id
          }
          setoptions(current => [...current, option])
        })

      }).catch((error) => {
        if (error?.response?.status == 401 || error?.response?.status == 403) {
          navigate("/logout/process")
        }
      });
  }, []);

  return (
    <Layout>
      <SerialNav deviceInfo={ deviceInfo } />
      <div className="row flex-wrap">
        <div className="col-md-12 col-lg-8 order-md-2 order-lg-1  mb-4">
          <LiveScreen />
        </div>
        <div className="col-md-12 col-lg-4 order-md-1 order-lg-2">
          <div className="row">
            <div className="col-md-12  mb-4">
              <div className="card d-flex   flex-column">
                <div className="col-12 col-sm-8 col-md-12 col-lg-12">
                  <DeviceInfo deviceInfo={ deviceInfo } /></div>
                <hr />
                <div className="d-flex flex-column flex-sm-row flex-lg-column col-12 col-sm-4 col-md-12">
                  {/* <hr />  */ }
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12 col-lg-8 order-md-4 mb-4 order-lg-3">

          <Map />
          {/* <DeviceSetup /> */ }
        </div>
        <div className="col-md-12 col-lg-4 order-md-3 mb-4 order-lg-4">
          <ChartComponents />
        </div>
      </div>
    </Layout>
  );
};

export default DeviceShow;