import React, { useState, useEffect } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';

import Layout from "../Layout/Layout";
import { NetworkManager } from '../../config/NetworkManager';
import { useTitle } from '../../config/global';

import { usePackageContext } from '../Contexts/PackagesContext';
import { useGlobalContext } from '../Contexts/GlobalContext';
import CreateUpdatePackage from './components/CreateUpdatePackage';
import DeleteConfirmation from '../SharedComponents/DeleteConfirmation';
import PackagesOrderingDialog from './components/PackagesOrderingDialog';


function PackagesDataTable () {
    useTitle('Packages');

    const { selectedPackage, setSelectedPackage, data, setData } = usePackageContext();
    const { setDisableBtn, rerender, setRerender, openDialog, dialogHandler, showToast } = useGlobalContext();

    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [packages, setPackages] = useState({});

    const Service = new NetworkManager();
    let loadLazyTimeout = null;

    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 15,
        page: 0,
        sortField: null,
        sortOrder: 1,
        filters: {
            'title': { value: '', matchMode: 'contains' },
            'show_in_dashboard': { value: '', matchMode: 'contains' },
        }
    });

    //Data Table Handlers
    const loadLazyData = () => {
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }
        //imitate delay of a backend call
        loadLazyTimeout = setTimeout(() => {
            Service.datatable('packages', lazyParams).then(data => {
                setTotalRecords(data.pagination.total);
                setData(data.data);
                setLoading(false);
            });
        }, Math.random() * 1000 + 250);
    }

    const onPage = (event) => {
        setLazyParams(event);
    }

    const onSort = (event) => {
        setLazyParams(event);
    }

    const onFilter = (event) => {
        event['first'] = 0;
        setLazyParams(event);
    }

    useEffect(() => {
        loadLazyData();
    }, [lazyParams])

    useEffect(() => {
        if (rerender) {
            loadLazyData()
        }
        setRerender(false)
    }, [rerender])

    //Delete Company Handler
    const confirmDeleteUser = (data) => {
        setSelectedPackage(data);
        dialogHandler("deletePackage");
    }

    const deleteCompany = () => {
        setDisableBtn(true);
        Service.deleteRow('packages', selectedPackage);
        dialogHandler("deletePackage");
        setSelectedPackage({});

        loadLazyData();
        setDisableBtn(false);
        showToast('success', 'Delete Package', 'Package Deleted Successfully!');
    }

    // Data Table Body Template
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="d-inline-block text-nowrap">

                {/* Update company */ }
                <Tooltip target={ `.update-button-${ rowData.id }` } showDelay={ 100 } className="fs-8" />
                <button className={ `btn btn-sm btn-icon update-button-${ rowData.id }` } data-pr-position="bottom" data-pr-tooltip="
                Update" onClick={ () => editCompany(rowData) }><i className="pi pi-pencil"></i></button>

                {/* Delete company */ }
                <Tooltip target={ `.delete-button-${ rowData.id }` } showDelay={ 100 } className="fs-8" />
                <button className={ `btn btn-sm btn-icon delete-record delete-button-${ rowData.id }` }
                    data-pr-position="bottom"
                    data-pr-tooltip="Delete"
                    onClick={ () => confirmDeleteUser(rowData) } >
                    <i className="bx bx-trash"></i>
                </button>

            </div>
        );
    }

    const showIbDashboardTemplate = (rowData) => {
        return <>
            <div className='text-center'>
                <span className={ `text-center text-capitalize badge ${ rowData.show_in_dashboard ? "bg-label-success" : "bg-label-danger" } ` }>{ rowData.show_in_dashboard ? "Show" : "Hidden" }</span>
            </div>
        </>
    }

    //Dialog Handler
    const createPackage = () => {
        setSelectedPackage({});
        dialogHandler("createPackage");
    }

    //Dialog Handler
    const orderPackages = () => {
        dialogHandler("orderPackages");
    }

    const editCompany = (data) => {
        setSelectedPackage(data);
        dialogHandler("createPackage");
    }

    useEffect(() => {
        const ordered   = [...data].sort((a, b) => a["order"] - b["order"])
        var _packages = []
        
         Object.values(ordered).forEach(_package => {
            _packages =   {..._packages, [_package.id]: { package_id: _package.id, name: _package.title }}
        })
        setPackages(_packages)
    }, [data])

    return (
        <Layout>
            <div className="card">
                <div className="card-header d-flex align-items-center justify-content-between">
                    <h4 className="card-title m-0 me-2">Packages </h4>
                    <div>
                        <button type="button" disabled = {loading} className="btn btn-primary me-sm-3 me-1" onClick={ orderPackages }>
                        <span className="p-sortable-column-icon pi pi-fw pi-sort-alt"></span>  Order Packages
                        </button>
                        <button type="button"  disabled = {loading} className="btn btn-primary me-sm-3 me-1" onClick={ createPackage }>
                            <span className="tf-icons bx bx-user"></span> Create Package
                        </button>
                    </div>
                </div>

                <div className='table-responsive text-nowrap'>
                    <DataTable value={ data } lazy filterDisplay="row" responsiveLayout="scroll" dataKey="id"
                        paginator first={ lazyParams.first } rows={ lazyParams.rows } rowsPerPageOptions={ [15, 25, 50, 100] }
                        totalRecords={ totalRecords } onPage={ onPage }
                        onSort={ onSort } sortField={ lazyParams.sortField } sortOrder={ lazyParams.sortOrder }
                        onFilter={ onFilter } filters={ lazyParams.filters } loading={ loading } className="table"
                    >
                        <Column field="title" header="Title" className='text-center' sortable filter filterPlaceholder="Search by Title" />
                        <Column field="show_in_dashboard" className='text-center' body={ showIbDashboardTemplate } header="Show in dashboard" sortable />
                        <Column field="order" className='text-center' header="Order" sortable />
                        <Column body={ actionBodyTemplate } exportable={ false } style={ { minWidth: '8rem' } }></Column>
                    </DataTable>
                </div>

                {
                    openDialog.createPackage && <CreateUpdatePackage />
                }

                {
                    openDialog.orderPackages && <PackagesOrderingDialog packages={ packages } setRerender={setRerender} />
                }

                {
                    openDialog.deletePackage &&
                    <DeleteConfirmation deleteHandler={ deleteCompany } itemName={ selectedPackage.title } dialogKey={ "deletePackage" } />
                }
            </div>
        </Layout>
    );
}

export default PackagesDataTable