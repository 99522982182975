import React, { useEffect } from 'react';
import { useForm, Controller } from "react-hook-form";

import { getFormErrorMessage } from '../../../config/global';
import { useGlobalContext } from '../../Contexts/GlobalContext';


import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { Spinner } from 'react-bootstrap';
import axiosAdminInstance from '../../../config/AxiosInstances/AxiosAdminInstance';

const UpdateCompany = ({ company, setReload }) => {
    const { openDialog, dialogHandler, showToast, disableBtn, setDisableBtn } = useGlobalContext();
    const { control, formState: { errors }, handleSubmit, reset } = useForm();

    const status = [
        { name: 'Active', code: "ACTIVE" },
        { name: 'Inactive', code: 'INACTIVE' },
    ];

    useEffect(() => {
        reset(company)
    }, [company])

    const onSubmit = (data) => {
        // setDisableBtn(true)
        axiosAdminInstance.put(
            `/companies/${ company.id }/update`,
            {
                "allowed_media_usage": parseInt(data.allowed_media_usage),
                "status": data.status,
            },
          
        ).then((result) => {
            setReload(true)
            setDisableBtn(false)
            dialogHandler("updateCompany");
            showToast('success', 'Company Updating', result?.data?.data?.msg);

        }).catch((error) => {
            setDisableBtn(false);
            if (error?.response?.data?.errors)
                Object.values(error?.response?.data?.errors).forEach(error => {
                    showToast('error', 'Company Updating', error[0]);
                });
        });
    };
    return (
        <Dialog
            visible={ openDialog.updateCompany }
            onHide={ () => dialogHandler("updateCompany") }
            position="top" breakpoints={ { '960px': '95vw' } } style={ { width: '600px', marginTop: "100px" } }
            header="Update company" modal
            className="p-fluid  "
        >
            <form onSubmit={ handleSubmit(onSubmit) } className="d-flex flex-column justify-content-center">
                <div className='px-5 my-5'>

                    {/* allowed total devices */ }
                    {/* <div className={ ` col-12 mb-4` }>
                        <div className="field">
                            <label className="fs-8 fw-light">Allowed Total Devices</label>
                            <span className="p-float-label">
                                <Controller name="allowed_device_number" control={ control }
                                    rules={ {
                                        required: 'This field is required.',
                                        min: {
                                            value: 1,
                                            message: "Minimum Total media Devices is 1!",
                                        },
                                    } }
                                    render={ ({ field, fieldState }) => (
                                        <InputNumber
                                            id={ field.allowed_device_number }
                                            { ...field }
                                            value={ field.value }
                                            inputRef={ field.ref }
                                            onChange={ (e) => field.onChange(e.value) }
                                            className={ `w-100  ${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                                            mode="decimal" useGrouping={ false } />
                                    ) } />
                            </span>
                            { getFormErrorMessage('allowed_device_number', errors) }
                        </div>
                    </div> */}

                    {/* allowed media usage */ }
                    <div className={ ` col-12 mb-4` }>
                        <div className="field">
                            <label className="fs-8 fw-light">Allowed Media Usage (GB)</label>
                            <span className="p-float-label">
                                <Controller name="allowed_media_usage" control={ control }
                                    rules={ {
                                        required: 'allowed media usage is required.',
                                        min: {
                                            value: 1,
                                            message: "Minimum allowed media usage is 1!",
                                        },
                                    } }
                                    render={ ({ field, fieldState }) => (
                                        <InputNumber
                                            mode="decimal" useGrouping={ false }
                                            id={ field.allowed_media_usage } { ...field }
                                            value={ field.value }
                                            inputRef={ field.ref }
                                            onChange={ (e) => field.onChange(e.value) }
                                            className={ `w-100  ${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                                        />
                                    ) } />
                            </span>
                            { getFormErrorMessage('allowed_media_usage', errors) }
                        </div>
                    </div>

                    {/* status input */ }
                    <div className={ ` col-12` }>
                        <div className="field">
                            <label className="fs-8 fw-light" htmlFor="basic-default-company">  Status </label>
                            <span className="p-float-label">
                                <Controller name="status" control={ control }
                                    rules={ { required: 'Status is required.' } }
                                    render={ ({ field, fieldState }) =>
                                        <Dropdown id={ field.name } { ...field }
                                            onChange={ (e) => field.onChange(e.value) }
                                            options={ status }
                                            inputRef={ field.ref }
                                            optionLabel="name"
                                            optionValue="code"
                                            className={ `w-100  ${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                                        />
                                    } />
                                { getFormErrorMessage('status', errors) }
                            </span>
                        </div>
                    </div>
                </div>
                <div className="col-12 mt-3 d-flex justify-content-center py-3 border-top ">
                    <button type="submit" className="btn btn-primary me-2" disabled={ disableBtn ? true : false }>
                        { disableBtn ?
                            <Spinner as="span" animation="border" size="sm" role="status" className='mx-3' aria-hidden="true" />
                            :
                            "Update"
                        }
                    </button>
                    <button type="reset" className="btn btn-label-secondary me-3"
                        data-bs-dismiss="modal" aria-label="Close"
                        disabled={ disableBtn ? true : false }
                        onClick={ () => dialogHandler("updateCompany") }>
                        Cancel
                    </button>
                </div>

            </form>
        </Dialog>
    )
}
export default UpdateCompany;