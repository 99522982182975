import React, { Component } from 'react';
import { Outlet, Navigate } from 'react-router-dom'

function ProtectedRouter () {

    const token = localStorage.getItem("access_token");
    const isVerified = localStorage.getItem("email_verified_at");

    if (token) {
        if (isVerified !== "null") {
            return <Outlet />
        } else {
            return <Navigate to="/verify/email" />;
        }
    } else {
        return <Navigate to="/" />;
    }
}

function ProtectedRouterTwo () {
    const token = localStorage.getItem("access_token");

    if (token) {
        return <Navigate to="/dashboard" />
    } else {
        return <Outlet />;
    }

}

export { ProtectedRouter, ProtectedRouterTwo };