import React from 'react'
import { Controller} from "react-hook-form";

import { getFormErrorMessage } from '../../../../config/global';

import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Checkbox } from 'primereact/checkbox';

function Step1 ({ isCreation, control, errors, trigger, showInDashboard, setShowInDashboard }) {

    const handleInputChange = async (field) => {
        await trigger(field);
    };

    return (
        <>
            <div className="col-12 d-flex mb-3 px-3">
                {/* title input */ }
                <div className="field col-6 me-2">
                    <label className="text-capitalize fs-6 "> Title  </label>
                    <span className="p-float-label me-2">
                        <Controller name="title" control={ control }
                            rules={ { required: 'Title is required.' } }
                            render={ ({ field, fieldState }) => (
                                <InputText
                                    disabled={ isCreation ? false : true }
                                    id={ field.name }
                                    { ...field }
                                    onChange={ (e) => { field.onChange(e.target.value); handleInputChange('title') } }
                                    className={ `w-100  ${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                                />
                            ) } />
                    </span>
                    { getFormErrorMessage('title', errors) }
                </div>

                {/* upload_once_content_capacity input */ }
                <div className="field  col-6 me-2">
                    <label className="text-capitalize fs-6 " > upload once content capacity </label>
                    <span className="p-float-label">
                        <Controller name="upload_once_content_capacity" control={ control }
                            rules={ { required: 'Price is required.' } }
                            render={ ({ field, fieldState }) => (
                                <InputNumber id={ field.name }
                                    { ...field }
                                    value={ field.value }
                                    inputRef={ field.ref }
                                    onChange={ (e) => { field.onChange(e.value); handleInputChange('upload_once_content_capacity') } }
                                    className={ `w-100  ${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                                    mode="decimal" useGrouping={ false } />
                            ) } />
                    </span>
                    { getFormErrorMessage('upload_once_content_capacity', errors) }
                </div>
            </div>

            <div className="col-12  d-flex mb-3 px-3">
                {/* media_storage_capacity input */ }
                <div className="field col-6 me-2">
                    <label className="text-capitalize fs-6 "> Media storage capacity  </label>
                    <span className="p-float-label">
                        <Controller name="media_storage_capacity" control={ control }
                            rules={ { required: 'Media storage capacity is required.' } }
                            render={ ({ field, fieldState }) => (
                                <InputNumber id={ field.name }
                                    { ...field }
                                    value={ field.value }
                                    inputRef={ field.ref }
                                    onChange={ (e) => { field.onChange(e.value); handleInputChange('media_storage_capacity') } }
                                    className={ `w-100  ${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                                    mode="decimal" useGrouping={ false } />
                            ) } />
                    </span>
                    { getFormErrorMessage('media_storage_capacity', errors) }
                </div>

                {/* playlist_capacity input */ }
                <div className="field  col-6 me-2">
                    <label className="text-capitalize fs-6 " >playlist capacity </label>
                    <span className="p-float-label">
                        <Controller name="playlist_capacity" control={ control }
                            rules={ { required: 'Playlist capacity is required.' } }
                            render={ ({ field, fieldState }) => (
                                <InputNumber id={ field.name }
                                    { ...field }
                                    value={ field.value }
                                    inputRef={ field.ref }
                                    onChange={ (e) => { field.onChange(e.value); handleInputChange('playlist_capacity') } }
                                    className={ `w-100  ${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                                    mode="decimal" useGrouping={ false } />
                            ) } />
                    </span>
                    { getFormErrorMessage('playlist_capacity', errors) }
                </div>
            </div>

            <div className="col-12  d-flex mb-3 px-3">
                {/* show_in_dashboard input */ }
                <div className="field col-6 me-2 d-flex align-items-center mt-2">
                    <span className="p-float-label me-2">
                        <Controller name="show_in_dashboard" control={ control }
                            rules={ { required: false } }
                            render={ ({ field, fieldState }) => {
                                return <Checkbox id={ field.name }
                                    name="show_in_dashboard"
                                    { ...field }
                                    value="show_in_dashboard"
                                    inputRef={ field.ref }
                                    checked={ showInDashboard == 1 ? true : false }
                                    onChange={ (e) => { field.onChange(e.value); setShowInDashboard(prev => !prev) } }
                                    className={ `${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                                    inputId={"show_in_dashboard-1"}
                                />
                            }
                            } />
                    </span>
                    <label className="text-capitalize fs-6 cursor-pointer" htmlFor="show_in_dashboard-1">Show in Dashboard </label>
                </div>
            </div>

        </>
    )
}

export default Step1