import React from 'react'

import { Message } from 'primereact/message';
import Layout from '../Layout/Layout';

function Reports () {
    return (
        <Layout >
            <div className="d-flex justify-content-center w-100 mt-5 ">
                <Message severity="info" text="Coming soon!" className='w-75' />
            </div>
        </Layout>

    )
}

export default Reports