import React, { useState, useEffect } from 'react';
import { useForm, Controller } from "react-hook-form";
import axios from 'axios';

import '../../assets/vendor/css/pages/page-auth.css';
import avatar from '../../assets/img/unknown_person.jpg';
import 'react-phone-input-2/lib/style.css'

import { useTitle, getFormErrorMessage } from '../../config/global';
import { useGlobalContext } from '../Contexts/GlobalContext';
import Layout from '../Layout/Layout';

import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import PhoneInput from 'react-phone-input-2'
import axiosAdminInstance from '../../config/AxiosInstances/AxiosAdminInstance';

const Account = () => {
    useTitle("Account");

    const { formState: { errors }, reset, handleSubmit, control } = useForm();
    const { setProfileImage, showToast } = useGlobalContext();

    const [previewImg, setPreviewImg] = useState(localStorage.getItem("image") == "null" ? avatar : localStorage.getItem("image"));
    const [newImage, setNewImage] = useState(localStorage.getItem("image") == "null" ? avatar : localStorage.getItem("image"));

    const [countryCode, setCountryCode] = useState("");
    const [userInfo, setUserInfo] = useState({
        name: "",
        email: "",
        phone: "",

    });

    useEffect(() => {
        axios.get("https://ipapi.co/json/")
            .then((response) => {
                let data = response.data;
                setCountryCode(data.country_code);
            })

        axiosAdminInstance.get(`/users/show`)
            .then((result) => {
                localStorage.setItem("name", result.data?.data?.name);
                localStorage.setItem("image", result.data?.data?.image);
                localStorage.setItem("phone", result.data?.data?.phone);
                localStorage.setItem("email", result.data?.data?.email);
                setUserInfo({
                    "name": result.data?.data?.name,
                    "email": result.data?.data?.email,
                    'phone': result.data?.data?.phone ?? "",
                })
                reset({
                    "name": result.data?.data?.name,
                    "email": result.data?.data?.email,
                    "phone": result.data?.data?.phone ?? "",
                })

            })
    }, [])

    const onSubmit = (data) => {
        var formData = new FormData();

        formData.append("name", data.name);
        formData.append("profile_img", newImage);
        formData.append("phone", data.phone);

        axiosAdminInstance.post(`/users/update`, formData)
            .then((result) => {
                setProfileImage(result.data?.data?.image)
                localStorage.setItem("name", result.data?.data?.name);
                localStorage.setItem("image", result.data?.data?.image);
                localStorage.setItem("phone", result.data?.data?.phone);
                showToast("success", "Update Profile", result?.data?.data?.msg)
            })
            .catch((error) => {
                showToast("error", "Update Profile", error?.response?.data?.message)
            });
    };

    const previewImage = (e) => {
        setPreviewImg(URL.createObjectURL(e.target.files[0]) ?? localStorage.getItem("image"))
        setNewImage(e.target.files[0]);
    }

    useEffect(() => {
        reset(userInfo)
    }, [])

    return (

        <Layout>
            <div className="content-wrapper">
                {/* Content */ }
                <div className="mx-3 mx-md-4 flex-grow-1 container-p-y">
                    <div className="row">
                        <div className="col-md-12">
                            <ul className="nav nav-pills flex-column flex-md-row mb-3">
                                <li className="nav-item">
                                    <a className="nav-link active" href="/"><i className="bx bx-user me-1" /> Account</a>
                                </li>
                            </ul>

                            <form onSubmit={ handleSubmit(onSubmit) } className="mb-3" encType="multipart/form-data" >
                                <div className="card mb-4">
                                    <h5 className="card-header">Profile Details</h5>
                                    {/* Account */ }
                                    <div className="card-body">
                                        <div className="d-flex align-items-start align-items-sm-center gap-4">
                                            <img src={ previewImg === "" ? avatar : previewImg } alt="user-avatar" accept="image/png, image/jpg, image/jpeg" className="d-block rounded" height={ 100 } width={ 100 } id="uploadedAvatar" />
                                            <div className="button-wrapper">
                                                <label htmlFor="upload" className="btn btn-primary me-2 mb-4" tabIndex={ 0 }>
                                                    <span className="d-none d-sm-block">Choose new photo</span>
                                                    <i className="bx bx-upload d-block d-sm-none" />
                                                    <Controller name="name" control={ control }
                                                        render={ ({ field, fieldState }) => (
                                                            <input onChange={ previewImage } type="file" id="upload" className="account-file-input" hidden accept="image/png, image/jpeg" />

                                                        ) } />
                                                </label>
                                                <button type="button" className="btn btn-outline-secondary account-image-reset mb-4"
                                                    onClick={ () => { setPreviewImg(localStorage.getItem("image") ?? "") } }>
                                                    <i className="bx bx-reset d-block d-sm-none" />
                                                    <span className="d-none d-sm-block" >Cancel</span>
                                                </button>
                                                <p className="text-muted mb-0">Allowed JPG, JPEG or PNG.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="my-0" />
                                    <div className="card-body">
                                        <div className="d-flex flex-wrap">
                                            {/* Name */ }
                                            <div className="mb-3 col-6  ">
                                                <div className="field me-4">
                                                    <label className="form-label"> Name</label>
                                                    <span className="p-float-label mb-1">
                                                        <Controller name="name" control={ control }
                                                            rules={ { required: 'Name is required.' } }
                                                            render={ ({ field, fieldState }) => (
                                                                <InputText id={ field.name } { ...field } autoFocus
                                                                    className={ `w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` } />
                                                            ) } />
                                                    </span>
                                                    { getFormErrorMessage('name', errors) }
                                                </div>
                                            </div>

                                            {/* phone */ }
                                            <div className="col-6  form-password-toggle">
                                                <div className="field me-4">
                                                    <label className="form-label">phone</label>
                                                    <span className="p-float-label mb-1">
                                                        <Controller
                                                            control={ control }
                                                            name="phone"
                                                            rules={ {
                                                                required: "Phone number is required!",
                                                                minLength: {
                                                                    value: 10,
                                                                    message: "Phone number too short!",
                                                                },
                                                                maxLength: {
                                                                    value: 13,
                                                                    message: "Phone number too long!",
                                                                }
                                                            } }
                                                            render={ ({ field: { ref, ...field }, fieldState }) => (
                                                                <PhoneInput
                                                                    { ...field }
                                                                    inputExtraProps={ {
                                                                        ref,
                                                                        required: true,
                                                                        autoFocus: true
                                                                    } }
                                                                    // country={ countryCode.toLocaleLowerCase() }
                                                                    countryCodeEditable={ false }
                                                                    specialLabel={ "Player Mobile Number" }
                                                                    inputClass={ `w-100 py-3 ${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                                                                    containerClass={ `w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                                                                />
                                                            ) }
                                                        />
                                                    </span>
                                                    { getFormErrorMessage('phone', errors) }
                                                </div>
                                            </div>

                                            {/* email */ }
                                            <div className="mb-3 col-6">
                                                <div className="field me-2">
                                                    <label className="form-label">Email</label>
                                                    <span className="p-float-label">
                                                        <Controller name="email" control={ control }
                                                            rules={ {
                                                                required: 'Email Address is required.',
                                                                pattern: {
                                                                    value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                                                                    message: "Invalid email address",
                                                                }
                                                            } }
                                                            render={ ({ field, fieldState }) => (
                                                                <InputText id={ field.email } { ...field } autoFocus disabled
                                                                    className={ `w-100 ${ classNames({ 'p-invalid': fieldState.invalid }) }` } />
                                                            ) } />
                                                    </span>
                                                    { getFormErrorMessage('email', errors) }
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 d-flex justify-content-end' >
                                            <button className="btn btn-primary d-grid  mt-5">Save Changes</button>
                                        </div>
                                    </div>
                                    {/* /Account */ }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="content-backdrop fade" />
            </div>

        </Layout>
    );
}

export default Account;