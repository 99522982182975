import React from 'react'
import { useForm, Controller } from "react-hook-form";

import { getFormErrorMessage } from '../../../../../config/global';
import { useGlobalContext } from '../../../../Contexts/GlobalContext';

import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Spinner } from 'react-bootstrap';
import { classNames } from 'primereact/utils';
import { useCompaniesContext } from '../../../../Contexts/CompaniesContext';
import { useParams } from 'react-router-dom';
import axiosAdminInstance from '../../../../../config/AxiosInstances/AxiosAdminInstance';
import { InputNumber } from 'primereact/inputnumber';
import { useState } from 'react';
import { useEffect } from 'react';

const periodOptions = [
    { name: 'One Month', code: 'ONE_MONTH' },
    { name: 'Six Months', code: 'SIX_MONTHS' },
    { name: 'One year', code: 'ONE_YEAR' },
];

const paymentOptions = [
    { name: 'Bank Transaction', code: 'bank' },
    { name: 'Gift', code: 'gift' },
];

function AddSubscription () {
    const { formState: { errors }, control, handleSubmit, reset } = useForm();
    const { disableBtn, setDisableBtn, openDialog, dialogHandler, loadingHandler, showToast } = useGlobalContext();
    const { packageOptions } = useCompaniesContext();
    const { id } = useParams();

    const [typeValue, setTypeValue] = useState();

    const onSubmit = (data) => {
        setDisableBtn(true)

        axiosAdminInstance.post(`/companies/add/subscription`,
            {
                "number_of_devices": data.number_of_devices,
                "company_id": id,
                "package_id": data.package_id,
                "amount": data.type == "gift" ? 0 : data.amount,
                "period": data.period,
                "type": data.type,
            }
        ).then((result) => {
            setDisableBtn(false)
            loadingHandler("reload");
            dialogHandler("addSubscription");
            showToast('success', 'Subscription  Addition', "Device Updated Successfully!");
        }).catch((error) => {
            setDisableBtn(false);
            if (error?.response?.data?.errors)
                Object.values(error?.response?.data?.errors).forEach(error => {
                    showToast('error', 'Subscription Addition ', error[0]);
                });
            else
                showToast('error', 'Subscription  Addition', error?.response?.data?.message);

        });
    };


    return (
        <Dialog visible={ openDialog.addSubscription }
            style={ { width: '500px' } }
            header="Subscription Addition "
            modal className="p-fluid"
            breakpoints={ { '960px': '95vw' } }
            onHide={ () => dialogHandler("addSubscription") }>

            <form onSubmit={ handleSubmit(onSubmit) }>
                {/* Package input */ }
                <div className={ `col-12 mt-5 mb-3 px-4` }>
                    <div className="field">
                        <label className="form-label" htmlFor="basic-default-company"> Package  </label>
                        <span className="p-float-label">
                            <Controller name="package_id" control={ control }
                                rules={ { required: 'Package is required.' } }
                                render={ ({ field, fieldState }) =>
                                (<Dropdown id={ field.name } { ...field }
                                    onChange={ (e) => field.onChange(e.value) }
                                    options={ packageOptions }
                                    optionLabel="name"
                                    optionValue="code"
                                    inputRef={ field.ref }
                                    className={ `w-100  ${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                                />)
                                } />
                            { getFormErrorMessage('package_id', errors) }
                        </span>
                    </div>
                </div>

                {/* Status input */ }
                <div className={ `col-12 my-3 px-4` }>
                    <div className="field">
                        <label className="form-label" htmlFor="basic-default-company"> period </label>
                        <span className="p-float-label">
                            <Controller name="period" control={ control }
                                rules={ { required: 'period is required.' } }
                                render={ ({ field, fieldState }) =>
                                (<Dropdown id={ field.name } { ...field }
                                    onChange={ (e) => field.onChange(e.value) }
                                    options={ periodOptions }
                                    optionLabel="name"
                                    optionValue="code"
                                    inputRef={ field.ref }
                                    className={ `w-100  ${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                                />)
                                } />
                            { getFormErrorMessage('period', errors) }
                        </span>
                    </div>
                </div>

                {/* number of devices */ }
                <div className={ ` col-12 my-3 px-4` }>
                    <div className="field">
                        <label className="fs-8 fw-light">Number Of Devices</label>
                        <span className="p-float-label">
                            <Controller name="number_of_devices" control={ control }
                                rules={ {
                                    required: 'This field is required.',
                                    min: {
                                        value: 1,
                                        message: "Minimum Total media Devices is 1!",
                                    },
                                } }
                                render={ ({ field, fieldState }) => (
                                    <InputNumber
                                        id={ field.number_of_devices }
                                        { ...field }
                                        value={ field.value }
                                        inputRef={ field.ref }
                                        onChange={ (e) => field.onChange(e.value) }
                                        className={ `w-100  ${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                                        mode="decimal" useGrouping={ false } />
                                ) } />
                        </span>
                        { getFormErrorMessage('number_of_devices', errors) }
                    </div>
                </div>

                {/* type input */ }
                <div className={ `col-12 my-3 px-4` }>
                    <div className="field">
                        <label className="form-label" htmlFor="basic-default-company"> Type </label>
                        <span className="p-float-label">
                            <Controller name="type" control={ control }
                                rules={ { required: 'Type is required.' } }
                                render={ ({ field, fieldState }) =>
                                (<Dropdown id={ field.name } { ...field }
                                    onChange={ (e) => { field.onChange(e.value); setTypeValue(e.value) } }
                                    options={ paymentOptions }
                                    optionLabel="name"
                                    optionValue="code"
                                    inputRef={ field.ref }
                                    className={ `w-100  ${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                                />)
                                } />
                            { getFormErrorMessage('type', errors) }
                        </span>
                    </div>
                </div>

                {
                    typeValue == "bank" &&
                    // paid amount input 
                    <div className={ `col-12 my-3 px-4` }>
                        <div className="field">
                            <label className="form-label" htmlFor="basic-default-company"> Paid amount </label>
                            <span className="p-float-label">
                                <Controller name="amount" control={ control }
                                    rules={ { required: 'Paid amount is required.' } }
                                    render={ ({ field, fieldState }) =>
                                    (<InputNumber id={ field.name }
                                        { ...field }
                                        value={ field.value }
                                        inputRef={ field.ref }
                                        onChange={ (e) => { field.onChange(e.value) } }
                                        className={ `w-100  ${ classNames({ 'p-invalid': fieldState.invalid }) }` }
                                        mode="decimal" useGrouping={ false } />)
                                    } />
                                { getFormErrorMessage('amount', errors) }
                            </span>
                        </div>
                    </div>
                }

                <div className="col-12 text-center mt-5 mt-3 d-flex  align-items-end  justify-content-center py-4 border-top border-gray">
                    <button type="reset" className="btn btn-label-secondary me-3 " data-bs-dismiss="modal" aria-label="Close"
                        disabled={ disableBtn ? true : false }
                        onClick={ () => dialogHandler("addSubscription") }>
                        Cancel
                    </button>

                    <button type="submit" className="btn btn-primary me-2" disabled={ disableBtn ? true : false }>
                        {
                            disableBtn ?
                                <Spinner variant="white" as="span" animation="border" size="sm" role="status" className='mx-3' aria-hidden="true" />
                                :
                                "Add"
                        }
                    </button>
                </div>
            </form>
        </Dialog>
    )
}

export default AddSubscription