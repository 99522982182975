import React, { useState } from 'react'

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Spinner } from 'react-bootstrap';
import { useGlobalContext } from '../Contexts/GlobalContext';
 
function DeleteConfirmation ({ itemName, deleteHandler, dialogKey }) {
    const { openDialog, dialogHandler, disableBtn } = useGlobalContext();

    const footer = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" disabled={ disableBtn ? true : false } onClick={ () => dialogHandler(dialogKey) } />
            <Button label={ disableBtn ?
                <Spinner as="span" animation="border" size="sm" role="status" className='mx-3' aria-hidden="true" />
                :
                "Yes"
            } icon="pi pi-checks" className="p-button-text p-component p-confirm-dialog-accept p-button-danger" disabled={ disableBtn ? true : false } onClick={ deleteHandler } />
        </>
    );

    return (
        <>
            <Dialog visible={ openDialog[dialogKey] }
                style={ { width: '450px' } }
                breakpoints={ { '960px': '95vw' } }
                header="Confirm" modal
                footer={ footer }
                onHide={ () => dialogHandler(dialogKey) }>
                <div className="confirmation-content my-5">
                    <div className='text-center'>
                        <i className="pi pi-exclamation-triangle mr-3 d-block" style={ { fontSize: '2rem' } } />
                        <br />
                        <span>Are you sure you want to delete </span> <br /> <b>{ itemName } </b>?
                    </div>
                </div>
            </Dialog>
        </>


    )
}

export default DeleteConfirmation