import axios from 'axios';
import { englishTimeZone } from '../global';

const axiosAdminInstance = axios.create();

axiosAdminInstance.interceptors.request.use((config) => {
    const token = `Bearer ${ localStorage.getItem("access_token") }`;
    if (token) {
        config.headers['Authorization'] = `${ token }`;
    }
    config.headers['Content-Type'] = 'application/json';
    config.headers['Accept'] = 'application/json';
    config.headers['timezone'] = englishTimeZone;
    return config;
});

axiosAdminInstance.defaults.baseURL = process.env.REACT_APP_SECRET_CODE;

axiosAdminInstance.interceptors.response.use((response) => response,
    (error) => {

        if (error?.response?.status === 402) {
            window.location.href = '/profile';
        }

        if (( error.response.status == 403) &&
            !window.location.pathname.includes("login")) {
            window.location.href = '/logout/process';
        }

        return Promise.reject(error);
    })

export default axiosAdminInstance