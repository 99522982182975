import React, { createContext, useContext, useState } from "react";

const CompaniesContext = createContext({});

export const CompaniesProvider = (props) => {
    const [companyInfo, setCompanyInfo] = useState();
    const [packageOptions, setPackageOptions] = useState();
    const [selectedLog, setSelectedLog] = useState({});

    return (
        <CompaniesContext.Provider value={ {
            companyInfo, setCompanyInfo,
            packageOptions, setPackageOptions,
            selectedLog, setSelectedLog
        } }>
            { props.children }

        </CompaniesContext.Provider>
    )
}

export const useCompaniesContext = () => {
    return useContext(CompaniesContext)
}