import React, { useState, useEffect } from "react";
import { Link} from "react-router-dom";

import { getProfile } from "../../config/global";
import Logout from "../Auth/Logout/Logout";
 
import { Button } from "primereact/button";
import { Sidebar } from 'primereact/sidebar';
 import avatar from '../../assets/img/unknown_person.jpg';
import MenuList from "./MenuList";
import { useGlobalContext } from "../Contexts/GlobalContext";


const Profile = () => {
    const [visibleLeft, setVisibleLeft] = useState(false);
    const { profileImage, setProfileImage } = useGlobalContext();

    useEffect(() => {
        setProfileImage(localStorage.getItem("image") == "null" ? avatar : localStorage.getItem("image"))
    }, [])

    return (
        <>
            <nav className="layout-navbar  navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme" id="layout-navbar">
                <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
                    <Link className="dropdown-item" to="/profile">
                        <div className="d-flex">
                            <div className="flex-shrink-0 me-3">
                                <div className="avatar avatar-online">
                                    <img src={ profileImage } className="w-px-40 object-fit-cover rounded-circle" />
                                </div>
                            </div>
                            <div className="flex-grow-1">
                                <span className="fw-semibold d-block">{ getProfile().name }</span>
                                <small className="text-muted">My Profile</small>
                            </div>
                        </div>

                    </Link>

                    <div className="navbar-nav flex-row align-items-center ms-auto d-none d-lg-flex">
                        {/* <Link className="dropdown-item " to="/help">
                            <i className="bx bx-support me-2" />
                            <span className="align-middle">Help</span>
                        </Link>

                        <Link className="dropdown-item " to="/fqa">
                            <i className="bx bx-help-circle me-2" />
                            <span className="align-middle">FAQ</span>
                        </Link> */}
                        <Logout />
                    </div>
                </div>
                <div className="">
                    <Button icon="fas fa-bars" onClick={ () => setVisibleLeft(true) } className="mr-2 p-2 bg-transparent border-0 text-light d-block d-xl-none" />
                </div>

            </nav>

            <Sidebar visible={ visibleLeft } position="right" onHide={ () => setVisibleLeft(false) }>
                <aside id="layout-menu" className="menu-vertical menu bg-menu-theme mx-auto ">
                    <MenuList />

                    <Logout classes={ "ps-3 p-2  " } />
                </aside>
            </Sidebar>
        </>
    );

}
export default Profile;
