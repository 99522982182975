import { createContext, useContext, useState } from "react";

const PackageContext = createContext({});

export const PackageProvider = (props) => {

    let defaultPeriods = [
        {
            name: "one_month",
            percentage: 0,
            period: 1,
            price: 0,
        },
        {
            name: "six_months",
            percentage: 0,
            period: 6,
            price: 0,
        },
        {
            name: "one_year",
            percentage: 0,
            period: 12,
            price: 0
        }];

    const [data, setData] = useState([]);
    const [selectedPackage, setSelectedPackage] = useState({});

    const [selectedMediaTypes, setSelectedMediaTypes] = useState([]);
    const [selectedLayers, setSelectedLayers] = useState([]);
    const [selectedFeature, setSelectedFeature] = useState([]);
    const [selectedPeriods, setSelectedPeriods] = useState(defaultPeriods);

    const [periodPrices, setPeriodPrices] = useState({
        "one_month": 0,
        "six_months": 0,
        "one_year": 0,
    });

    const [setRerender, setLoading] = useState(false);

    return (
        <PackageContext.Provider value={ {
            data, setData,
            selectedPackage, setSelectedPackage,

            selectedFeature, setSelectedFeature,
            selectedMediaTypes, setSelectedMediaTypes,
            selectedLayers, setSelectedLayers,
            selectedPeriods, setSelectedPeriods,

            periodPrices, setPeriodPrices,
            defaultPeriods,

            setRerender, setLoading
        } }>
            { props.children }
        </PackageContext.Provider>
    );
};

export const usePackageContext = () => {
    return useContext(PackageContext);
};