import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
 
 import { useGlobalContext } from '../../../../Contexts/GlobalContext';
import { NetworkManager } from '../../../../../config/NetworkManager';

function Users () {
    const { subLoaders, loadingHandler, showToast } = useGlobalContext();
    const { id } = useParams();

    const [totalRecords, setTotalRecords] = useState(0);
    const [devices, setDevices] = useState(null);
 
    const Service = new NetworkManager();
    let loadLazyTimeout = null;

    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 0,
        sortField: null,
        sortOrder: 1,
        filters: {
            'name': { value: '', matchMode: 'contains' },
         },
        isInteractive: 1,
        company_id: id
    });

    //Data Table Handlers
    const loadLazyData = () => {

        if (!subLoaders.devices)
            loadingHandler("users");

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }

        //imitate delay of a backend call
        loadLazyTimeout = setTimeout(() => {
            Service.datatable('users', lazyParams)
                .then(data => {
                    if (subLoaders.reload)
                        loadingHandler("reload");
                    setTotalRecords(data.pagination.total);
                    setDevices(data.data);
                    loadingHandler("users");
                });
        }, Math.random() * 1000 + 250);
    }

    const onPage = (event) => {
        event['filters'] = {};
        event['company_id'] = id;
        setLazyParams(event);
    }

    const onFilter = (event) => {
        event['first'] = 0;
        event['company_id'] = id
        setLazyParams(event);
    }

    const onSort = (event) => {
        event['company_id'] = id
        setLazyParams(event);
    }

    useEffect(() => {
        loadLazyData();
    }, [lazyParams])

    useEffect(() => {
        if (subLoaders.reload)
            loadLazyData();
    }, [subLoaders.reload])

    // Table body columns templates 
    const statusBodyTemplate = (rowData) => {
        return (
            <span className={ `text-center badge ${ rowData.status == "INACTIVE" ? "bg-label-danger" : "bg-label-success" } ` }>
                { rowData.status.toLowerCase() }
            </span>
        );
    }

     // Table body columns templates 
     const typeBodyTemplate = (rowData) => {
        return   rowData.type.toLowerCase()  
    }
    // const actionsBodyTemplate = (rowData) => {
    //     return (
    //         <div className="d-inline-block text-nowrap">

    //             {/* Activation company */ }
    //             <Tooltip target={ `.activation-button-${ rowData.id }` } showDelay={ 100 } className="fs-8" />
    //             <button className={ `btn btn-sm btn-icon activation-button-${ rowData.id }` }
    //                 data-pr-position="bottom"
    //                 data-pr-tooltip={ rowData.status == "ACTIVE" ? "Deactivation" : "Activation" }
    //             >
    //                 {
    //                     rowData.status == "ACTIVE" ?
    //                         <i class="fa-solid fa-plug-circle-xmark fs-6" onClick={ () => activationHandler(rowData, "INACTIVE") } ></i>
    //                         :
    //                         <i class="fa-solid fa-plug-circle-check fs-6" onClick={ () => activationHandler(rowData, "ACTIVE") }></i>
    //                 }
    //             </button>

    //             {/* Flush company */ }
    //             <Tooltip target={ `.flush-button-${ rowData.id }` } showDelay={ 100 } className="fs-8" />
    //             <button className={ `btn btn-sm btn-icon flush-button-${ rowData.id }` }
    //                 data-pr-position="bottom"
    //                 data-pr-tooltip="Flush"
    //                 onClick={ () => flushHandler(rowData) }
    //             >
    //                 <i className="bx bx-help-circle"></i>
    //             </button>

    //             {/* Restart company */ }
    //             <Tooltip target={ `.restart-button-${ rowData.id }` } showDelay={ 100 } className="fs-8" />
    //             <button className={ `btn btn-sm btn-icon restart-button-${ rowData.id }` }
    //                 data-pr-position="bottom"
    //                 data-pr-tooltip="Restart"
    //                 onClick={ () => restartHandler(rowData) }
    //             >
    //                 <i className="fas fa-power-off fs-6"></i>
    //             </button>

    //         </div>
    //     );
    // }


    return (
        <div className="card">
            <div className="card-header d-flex align-items-center justify-content-between">
                <h6 className="card-title m-0 me-2">Devices List </h6>
            </div>

            <div className='table-responsive text-nowrap stream_datatable position-relative'>

                <DataTable
                    value={ devices }
                    lazy
                    filterDisplay="row"
                    responsiveLayout="scroll"
                    dataKey="id"
                    paginator
                    first={ lazyParams.first }
                    rows={ lazyParams.rows }
                    rowsPerPageOptions={ [15, 25, 50, 100] }
                    totalRecords={ totalRecords }
                    onPage={ onPage }
                    onSort={ onSort }
                    onFilter={ onFilter }
                    sortField={ lazyParams.sortField }
                    sortOrder={ lazyParams.sortOrder }
                    filters={ lazyParams.filters }
                    loading={ subLoaders.devices }
                    className="table text-light"
                >
                    <Column field="name" header="Name" className='text-center ' sortable filter />
                    <Column field="email" header="Email" className='text-center ' sortable filter />
                    <Column field="type" header="type" className=' text-center  text-capitalize' body={ typeBodyTemplate } sortable filter />
                    <Column field="status" header="status" className=' text-center  text-capitalize' body={ statusBodyTemplate } sortable filter />
                    <Column field="last_login_at" header="last login " className=' text-center ' sortable />
                    <Column field="created_at" header="created date" className=' text-center ' sortable />
                    {/* <Column field="" header=" " className='text-center fs-8' body={ actionsBodyTemplate } /> */}
                </DataTable>

            </div>
        </div>
    )
}

export default Users
