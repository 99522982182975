import React from 'react'

import { Image } from 'react-bootstrap';

import { useCompaniesContext } from '../../../Contexts/CompaniesContext'
import avatar from '../../../../assets/img/unknown_person.jpg'

function CompanyInfo () {
    const { companyInfo } = useCompaniesContext();

    return (
        <div className="card p-3 flex-row">
            <div className="col-1 d-flex flex-column align-items-center justify-content-center border-end ">
                <Image src={ companyInfo?.company?.logo ?? avatar } alt="Image" width="75" roundedCircle className='border border-2' />
            </div>
            <div className="col-11 d-flex align-items-center justify-content-between flex-wrap px-3">
                <div className="col-12">
                    <h4 className='text-capitalize mb-3 '>{ companyInfo?.company?.name }</h4>
                </div>
                <div className="col-2 d-flex flex-column">
                    <span className=' fw-bold'>Creation date </span>
                    <span>{ companyInfo?.company?.created_at.slice(0, 11) } </span>
                </div>
                <div className="col-2 d-flex flex-column">
                    <span className=' fw-bold'>Last Login date </span>
                    <span>{ companyInfo?.company?.last_login_at?.slice(0, 11) ?? "---" } </span>
                </div>
                <div className="col-2 d-flex flex-column">
                    <span className=' fw-bold'>Total Devices </span>
                    <span>{ companyInfo?.totalDevices } </span>
                </div>
                <div className="col-2 d-flex flex-column">
                    <span className=' fw-bold'> Active Devices</span>
                    <span>{ companyInfo?.activeDevices } </span>
                </div>
                <div className="col-2 d-flex flex-column">
                    <span className=' fw-bold'>Inactive Devices </span>
                    <span>{ companyInfo?.inactiveDevices} </span>
                </div>
            </div>

        </div>
    )
}

export default CompanyInfo