import React, { useState, useEffect, useRef } from 'react';
import { useForm } from "react-hook-form";

import {  useTitle, dateFormate } from '../../config/global';
import { NetworkManager } from '../../config/NetworkManager';
import { DataTable } from 'primereact/datatable';

import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Tooltip } from 'primereact/tooltip';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';

import Layout from "../Layout/Layout";
import avatar from '../../assets/img/unknown_person.jpg';
import { useGlobalContext } from '../Contexts/GlobalContext';
import axiosAdminInstance from '../../config/AxiosInstances/AxiosAdminInstance';
 
let emptyUser = {
	name: null,
	email: null,
	phone: null
};

const UserDatatable = () => {
	useTitle('Users');

	const { register, formState: { errors }, handleSubmit } = useForm();
	const { showToast } = useGlobalContext();
	
	const [user, setUser] = useState(null);
	const [loading, setLoading] = useState(false);
	const [totalRecords, setTotalRecords] = useState(0);
	const [customers, setCustomers] = useState(null);
	const [deleteUserDialog, setDeleteUserDialog] = useState(false);
	const [userDialog, setUserDialog] = useState(false);

	const [lazyParams, setLazyParams] = useState({
		first: 0,
		rows: 15,
		page: 0,
		sortField: null,
		sortOrder: 1,
		filters: {
			'name': { value: '', matchMode: 'contains' },
			'email': { value: '', matchMode: 'contains' },
			'phone': { value: '', matchMode: 'contains' },
			'status': { value: '', matchMode: 'contains' },
		},
		filters_date: {
			'created_at': {
				"from": "",
				"to": ""
			}
		}
	});

	const statuses = [
		'ACTIVE', 'INACTIVE'
	];
	const [dates, setDates] = useState(null);
	const Service = new NetworkManager();

	let loadLazyTimeout = null;

	useEffect(() => {
		loadLazyData();
	}, [lazyParams])

	const loadLazyData = () => {
		setLoading(true);

		if (loadLazyTimeout) {
			clearTimeout(loadLazyTimeout);
		}

		//imitate delay of a backend call
		loadLazyTimeout = setTimeout(() => {
			Service.datatable('users', lazyParams).then(data => {
				setTotalRecords(data.pagination.total);
				setCustomers(data.data);
				setLoading(false);
			});
		}, Math.random() * 1000 + 250);
	}

	const onPage = (event) => {
		setLazyParams(event);
	}

	const onSort = (event) => {
		setLazyParams(event);
	}

	const onFilter = (event) => {
		event['first'] = 0;
		setLazyParams(event);
	}

	const OnFilterDate = (value) => {
		setDates(value);
		lazyParams['filters_date']["created_at"]['from'] = dateFormate(value[0])
		lazyParams['filters_date']["created_at"]['to'] = dateFormate(value[1])

		setLazyParams(lazyParams);
		loadLazyData();
	}

	const imageBodyTemplate = (rowData) => {
		return (
			<>
				<div className="d-flex justify-content-start align-items-center user-name">
					<div className="avatar-wrapper">
						<div className="avatar avatar-sm me-3">
							<img src={ `${ rowData.image ? rowData.image : avatar }` } className="rounded" />
						</div>
					</div>
					<div className="d-flex flex-column">
						<span className="fw-semibold">{ rowData.name }</span>
					</div>
				</div>
			</>);
	}

	const onSubmit = (data) => {
		axiosAdminInstance.post(  "/users/create", data)
			.then((result) => {
				hideDialog(false);
				loadLazyData();
				showToast("success", "Create User", result?.data?.data?.msg)
			})
			.catch((error) => {
				showToast("error", "Create User", error.response?.data?.message)
			});
	};

	const statusBodyTemplate = (rowData) => {
		return (
			<div className='text-center'>
				<span className="text-center badge bg-label-success">{ rowData.status.toLowerCase() }</span>
			</div>
		);
	}

	const hideDeleteUserDialog = () => {
		setDeleteUserDialog(false);
	}

	const confirmDeleteUser = (data) => {
		setUser(data);
		setDeleteUserDialog(true);
	}

	const deleteUser = () => {
		Service.deleteRow('users', user);
		loadLazyData();
		setDeleteUserDialog(false);
		showToast("success", "Delete User", 'User Deleted Successfully!')
	}

	const deleteUserDialogFooter = (
		<React.Fragment>
			<Button label="No" icon="pi pi-times" className="p-button-text" onClick={ hideDeleteUserDialog } />
			<Button label="Yes" icon="pi pi-checks" className="p-button-text p-component p-confirm-dialog-accept p-button-danger" onClick={ deleteUser } />
		</React.Fragment>
	);

	const hideDialog = () => {
		setUserDialog(false);
	}

	const openNew = () => {
		setUser(emptyUser);
		setUserDialog(true);
	}

	const actionBodyTemplate = (rowData) => {
		return (
			<>
				{
					rowData.email == localStorage.getItem("email") ? "" :
						(<div className="d-inline-block text-nowrap">
							{/* Delete playlist */ }
							<Tooltip target={ `.delete-button-${ rowData.id }` } showDelay={ 100 } className="fs-8" />
							<button className={ `btn btn-sm btn-icon delete-record delete-button-${ rowData.id }` } data-pr-position="bottom" data-pr-tooltip="Delete" onClick={ () => confirmDeleteUser(rowData) } ><i className="bx bx-trash"></i></button>
						</div>)
				}
			</>
		);
	}

	const statusItemTemplate = (option) => {
		return <span className={ `customer-badge status-${ option }` }>{ option }</span>;
	}

	const representativeFilterTemplate = (options) => {
		return <Dropdown value={ options.value } options={ statuses } itemTemplate={ statusItemTemplate } className="p-column-filter" showClear />;
	}

	const dateFilterTemplate = (options) => {
		return <Calendar selectionMode="range"
			value={ dates }
			dateFormat="yy-mm-dd"
			onChange={ (e) => OnFilterDate(e.value) }
		/>
	}

	const dateBodyTemplate = (rowData) => {
		return (rowData.created_at);
	}

	return (
		<Layout>
			<div className="card">
				<div className="card-header d-flex align-items-center justify-content-end">
					<h5 className="card-title m-0 me-auto">Team Members</h5>

					<button type="button" className="d-flex align-items-center btn btn-primary me-sm-3 me-1" onClick={ openNew }>
						<i className="tf-icons bx bx-user me-2"></i> New Member
					</button>
					{/* <button type="button" className="d-flex align-items-center btn btn-danger me-sm-3 me-1">
						<i className="tf-icons bx bx-lock  me-2"></i> Roles and Permissions
					</button> */}
				</div>
				<div className='table-responsive text-nowrap'>
					<DataTable value={ customers } lazy filterDisplay="row" responsiveLayout="scroll" dataKey="id"
						paginator first={ lazyParams.first } rows={ 15 } rowsPerPageOptions={ [15, 25, 50, 100] }
						totalRecords={ totalRecords } onPage={ onPage }
						onSort={ onSort } sortField={ lazyParams.sortField } sortOrder={ lazyParams.sortOrder }
						onFilter={ onFilter } filters={ lazyParams.filters } loading={ loading } className="table"
					>
						<Column field="name" header="Name" body={ imageBodyTemplate } sortable filter />
						<Column field="email" header="Email" sortable filter />
						<Column field="phone" header="Phone Number" />
						<Column header="status" filterField="status"
							body={ statusBodyTemplate }
							showFilterMatchModes={ false } filterMenuStyle={ { width: '14rem' } }
							style={ { minWidth: '14rem' } }
							filter filterElement={ representativeFilterTemplate } />

						<Column header="Created Date" filterField="created_at" dataType="date" style={ { minWidth: '10rem' } } body={ dateBodyTemplate }
							filter filterElement={ dateFilterTemplate } />


						<Column body={ actionBodyTemplate } exportable={ false } style={ { minWidth: '8rem' } }></Column>
					</DataTable>

					<Dialog visible={ deleteUserDialog } style={ { width: '450px' } } breakpoints={ { '960px': '95vw' } } header="Confirm" modal footer={ deleteUserDialogFooter } onHide={ hideDeleteUserDialog }>
						<div className="confirmation-content py-5">
							<div className='text-center'>
								<i className="pi pi-exclamation-triangle mr-3 d-block" style={ { fontSize: '2rem' } } />
								<br />
								{ user && <><span>Are you sure you want to delete ?</span> <br /> <b>{ user.name }</b> - <b>{ user.email }</b></> }
							</div>
						</div>
					</Dialog>

					<Dialog visible={ userDialog } style={ { width: '600px' } } breakpoints={ { '960px': '95vw' } } header="Member Details" modal className="p-fluid" onHide={ hideDialog }>
						<form onSubmit={ handleSubmit(onSubmit) } >
							<div className="px-4 my-3">
								<div className="mb-4">
									<label htmlFor="email" className="form-label">
										Email
									</label>

									<input
										{ ...register("email", { required: "Email Address is required", pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/ }) }
										aria-invalid={ errors.email ? "true" : "false" }
										className={ errors.email ? 'form-control is-invalid' : 'form-control' }
										placeholder="Email"
									/>

									{ errors.email && <p className='fv-plugins-message-container invalid-feedback'>{ errors.email?.message }</p> }
									{ errors?.email?.type === "pattern" && (
										<p className='fv-plugins-message-container invalid-feedback'>Invalid Email Address</p>
									) }
								</div>
								<div className="mb-4">
									<label htmlFor="name" className="form-label">
										Name
									</label>

									<input
										{ ...register("name", { required: "Full name is required" }) }
										aria-invalid={ errors.name ? "true" : "false" }
										className={ errors.name ? 'form-control is-invalid' : 'form-control' }
										placeholder="Full Name"
									/>

									{ errors.name && <p className='fv-plugins-message-container invalid-feedback'>{ errors.name?.message }</p> }
								</div>
								{/* <div className="mb-4">
									<label htmlFor="name" className="form-label">
										Phone Number
									</label>

									<input
										{ ...register("phone", { required: "Phone number is required" }) }
										aria-invalid={ errors.phone ? "true" : "false" }
										className={ errors.phone ? 'form-control is-invalid' : 'form-control' }
										placeholder="Phone Number"
									/>

									{ errors.phone && <p className='fv-plugins-message-container invalid-feedback'>{ errors.phone?.message }</p> }
								</div> */}
							</div>
							<div className="col-12 text-center d-flex  align-items-end  justify-content-center py-4 border-top border-gray">
								<button type="submit" className="btn btn-primary me-3 me-1">Save</button>
								<button type="reset" className="btn btn-label-secondary" data-bs-dismiss="modal" aria-label="Close" onClick={ hideDialog }>Cancel</button>
							</div>
						</form>
					</Dialog>
				</div>
			</div>
		</Layout>
	);
}
export default UserDatatable;
