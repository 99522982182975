
import React, { createContext, useContext, useState, useRef } from "react";
import { Toast } from 'primereact/toast';
import avatar from '../../assets/img/unknown_person.jpg';

const Global = createContext({});

export const GlobalProvider = (props) => {
    const toastRef = useRef(null);
    const [disableBtn, setDisableBtn] = useState(false);

    //Rerender parent passed on child components updates
    const [rerender, setRerender] = useState(false);

    // Profile Image
    const [profileImage, setProfileImage] = useState(localStorage.getItem("image") == "null" ? avatar : localStorage.getItem("image"));

    // Global Dialogs Controls
    const [openDialog, setOpenDialog] = useState({
        // Package 
        createPackage: false,
        deletePackage: false,
        orderPackages: false,
        
        // Companies 
        updateCompany: false,
        deleteCompany: false,
        logPreview: false,

        // Device 
        updateDevice: false,
        deleteDevice: false,
        activation:false,

    });

    // Global Loader Handler for Single Inputs Editing
    const [subLoaders, setSubLoaders] = useState({
        reload: false,
        
        // Interactive campaign
        durationLoader: false,

        // Company Profile
        showCompany: false,
        subscriptions: false,
        devices: false,
        logs: false,
    });

    const dialogHandler = (key) => {
        setOpenDialog(prev => ({
            ...prev, [key]: !prev[key]
        }))
    }

    const loadingHandler = (key) => {
        setSubLoaders(prev => ({
            ...prev, [key]: !prev[key]
        }))
    }

    const showToast = (severity, summary, detail, content = null) => {
        if (content == null)
            toastRef.current.show({ severity: severity, summary: summary, detail: detail, life: 3000 });
        else
            toastRef.current.show({ severity: severity, summary: summary, detail: detail, content: content, sticky: true });

    };

    return (
        <Global.Provider value={ {
            disableBtn, setDisableBtn,
            rerender, setRerender,

            profileImage, setProfileImage,

            openDialog, dialogHandler,
            subLoaders, loadingHandler,

            showToast
        } }>
            <Toast ref={ toastRef } position="bottom-center"></Toast>
            { props.children }
        </Global.Provider>
    )
}

export const useGlobalContext = () => {
    return useContext(Global)
}